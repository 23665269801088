import {Injectable} from "@angular/core";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {Store} from "@ngrx/store";
import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";

import * as ImportArticleActions from "./import-article.actions";
import {getImportArticleQuery} from "./import-article.selectors";
import {onNavigation} from "../../+state/on-navigation.operator";
import {ImportArticleService} from "../../utility/services/import-article.service";
import {Router} from "@angular/router";
import {StoreRootState} from "../../+state/root.reducer";
import {ImportComponent} from "../import.component";

@Injectable()
export class ImportArticleEffects {
  importArticleIndex$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(ImportComponent),
      map( () => ImportArticleActions.loadImportArticle())
    )
  );

  loadImportArticle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ImportArticleActions.loadImportArticle),
        concatLatestFrom(() => [
          this.store.select(getImportArticleQuery),
        ]),
        switchMap(([action, query]) => {
          return this.importArticleService.loadImportArticles('/' + query).pipe(
            tap(() => console.log('Store Load Complete')),
            map(res => ImportArticleActions.loadImportArticleSuccess({ importArticle: res.items, total: res.meta.itemCount })),
          );
        }),
        catchError((error) => {
          return of(ImportArticleActions.loadImportArticleFailure({ error }));
        })
      )
  );

  setImportArticleFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ImportArticleActions.setImportArticlePage,
        ImportArticleActions.setImportArticleTerm,
        ImportArticleActions.setImportArticleSort
      ),
      map(() => ImportArticleActions.loadImportArticle())
    )
  );

  createImportArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportArticleActions.createImportArticle),
      switchMap((action) => {
        return this.importArticleService.saveImportArticle(action.importArticle).pipe(
          map(created =>
            ImportArticleActions.saveImportArticleSuccess( { importArticle: created, insert: true })
          ),
          catchError(error => {
            return of(ImportArticleActions.saveImportArticleFailure({ error }));
          })
        )
      })
    )
  );

  deleteImportArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportArticleActions.deleteImportArticle),
      switchMap((action) => {
        return this.importArticleService.deleteImportArticle(action.id).pipe(
          map(() =>
            ImportArticleActions.deleteImportArticleSuccess( { id: action.id })
          ),
          catchError(error => {
            return of(ImportArticleActions.deleteImportArticleFailure({ error }));
          })
        )
      })
    )
  );

  constructor(private readonly actions$: Actions,
              private importArticleService: ImportArticleService,
              private router: Router,
              private store: Store<StoreRootState>) {
  }
}
