import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {Action, createReducer, on} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";

import * as ArticleStorageLocationActions from "./article-storage-location.actions";
import {StorageLocationArticle} from "@knust/api-interfaces";

export const ARTICLE_STORAGE_LOCATION_FEATURE_KEY = 'articleStorageLocation';

export interface State extends EntityState<StorageLocationArticle> {
  selectedId?: number; // which ArticleStorageLocation record has been selected
  loaded: boolean; // has the ArticleStorageLocation list been loaded
  error?: string | null; // last known error (if any)
  total: number;
  page: PageEvent;
  term: string;
  sort: Sort | null;
  showEmpty: boolean;
}

export interface ArticleStorageLocationPartialState {
  readonly [ARTICLE_STORAGE_LOCATION_FEATURE_KEY]: State;
}

export const articleStorageLocationAdapter: EntityAdapter<StorageLocationArticle> =
  createEntityAdapter<StorageLocationArticle>();

const initialPaginationState = {
  total: 0,
  page: {
    pageIndex: 0,
    pageSize: 25,
    length: 0
  },
  term: '',
  sort: null
};

export const initialState: State = articleStorageLocationAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  showEmpty: false,
  ...initialPaginationState
});

const articleStorageLocationReducer = createReducer(
  initialState,
  on(ArticleStorageLocationActions.loadArticleStorageLocations, (state) => ({ ...state, loaded: false, error: null })),
  on(ArticleStorageLocationActions.loadArticleStorageLocationsSuccess, (state, { articleStorageLocation, total }) =>
    articleStorageLocationAdapter.setAll(articleStorageLocation, { ...state, loaded: true, total })
  ),
  on(ArticleStorageLocationActions.loadArticleStorageLocationsFailure, (state, { error }) => ({ ...state, error })),
  on(ArticleStorageLocationActions.changeEmptyFilter, (state) => ({...state, showEmpty: !state.showEmpty})),
  on(ArticleStorageLocationActions.setSelectedArticleStorageLocation, (state, { id }) => ({ ...state, selectedId: id })),
  on(ArticleStorageLocationActions.loadArticleStorageLocationDetailSuccess, (state, { articleStorageLocation }) =>
    articleStorageLocationAdapter.setAll([articleStorageLocation], { ...state, loaded: false, ...initialPaginationState })
  ),
  on(ArticleStorageLocationActions.loadArticleStorageLocationDetailFailure, (state, { error }) => ({ ...state, error })),
  on(ArticleStorageLocationActions.setArticleStorageLocationPage, (state, { page }) => ({ ...state, page })),
  on(ArticleStorageLocationActions.setArticleStorageLocationTerm, (state, { term }) => ({ ...state, term })),
  on(ArticleStorageLocationActions.setArticleStorageLocationSort, (state, { sort }) => ({ ...state, sort })),
  on(ArticleStorageLocationActions.clearArticleStorageLocation, (state) =>
    articleStorageLocationAdapter.removeAll({ ...state, ...initialPaginationState })
  ),
  on(ArticleStorageLocationActions.saveArticleStorageLocationSuccess, (state, { articleStorageLocation, insert }) =>
    articleStorageLocationAdapter.upsertOne(articleStorageLocation, { ...state, total: (insert ? state.total + 1 : state.total) })
  ),
  on(ArticleStorageLocationActions.saveArticleStorageLocationFailure, (state, { error }) => ({ ...state, error })),
  on(ArticleStorageLocationActions.deleteArticleStorageLocationSuccess, (state, { id }) =>
    articleStorageLocationAdapter.removeOne(id + '', { ...state, total: state.total - 1 })
  ),
  on(ArticleStorageLocationActions.deleteArticleStorageLocationFailure, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return articleStorageLocationReducer(state, action);
}
