import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ActionsSubject, Store} from "@ngrx/store";
import {AbstractIndexComponent} from "../../utility/abstract-index/abstract-index.component";
import {StorageLocationPartialState} from "../../base-data/storage-location/+state/storage-location.reducer";
import {StorageLocation, StorageLocationArticle} from "@knust/api-interfaces";
import {BehaviorSubject, Observable} from "rxjs";
import {AbstractIndexCommands} from "../../utility/abstract-index/abstract-index-commands";
import {SelectionModel} from "@angular/cdk/collections";
import {addItemsToCart, addItemsToCartSuccess, changeItemsInTrunk, closeTrunk} from "../../trunks/+state/trunk.actions";
import {ColumnConfig} from "./column-config";
import {FormBuilder, UntypedFormGroup} from "@angular/forms";
import {ofType} from "@ngrx/effects";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {AuthService} from "../../utility/services/auth.service";
import {
  calculateNextIntervalStep,
  changeToNextIntervalStep
} from "../../utility/functions/change-to-next-interval-step.function";
import {NotificationService} from "../../utility/services/notification.service";

@UntilDestroy()
@Component({
  selector: 'knust-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DataTableComponent extends AbstractIndexComponent<StorageLocation, StorageLocationPartialState> implements OnInit {
  buyStockInputs: number[] = [];
  takeStockInputs: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  takeStockNotes: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  buyStockForm: UntypedFormGroup = this.fb.group({})

  changeToNextIntervalStep = changeToNextIntervalStep;

  @Input() disableFilterblock = false;
  @Input() disableNewButton = false;
  @Input() disableEditing = false;
  @Input() newButtonText = 'Neues Element hinzufügen';
  @Input() noDataText = 'Es wurden keine Daten gefunden.';
  @Input() enableExpandableRows = false;
  @Input() expandedColumns: string[] = [];
  @Input() allRowsExpanded = false;
  expandedElements: any[] = [];
  @Input() enableSelection = false;
  @Input() useClickAsSelection = false;
  @Input() propagateClicks = false;
  @Input () initialSelection: any[] = [];
  @Input () allowMultiSelect = true;
  @Input () hasMasterCheckbox = false;
  @Input () selectionHeaderText = 'Hinzugefügt';
  selection = new SelectionModel<any> ( this.allowMultiSelect, this.initialSelection );

  @Input() columnConfig: ColumnConfig[]                        = [];

  @Input() goToLastPage!: Observable<void>;

  @Output() entityRowClick             = new EventEmitter<any> ();
  @Output() addEntityClick             = new EventEmitter<any> ();
  @Output() addEmptyEntityClick        = new EventEmitter<any> ();
  @Output() deleteEntityClick          = new EventEmitter<any> ();
  @Output() restoreEntityClick         = new EventEmitter<any> ();
  @Output() toggleEntityClick          = new EventEmitter<any> ();
  @Output() entityActionClick          = new EventEmitter<any> ();
  @Output() duplicateEntityClick       = new EventEmitter<any> ();
  @Output() filterAddedEvent           = new EventEmitter<any> ();
  @Output() languageFilterChangedEvent = new EventEmitter<any> ();
  @Output() selectionUpdated           = new EventEmitter<any[]> ();
  @Output() inputChanged               = new EventEmitter<any> ();
  @Output() notesChanged               = new EventEmitter<any> ();
  @Output() closeTrunkClick            = new EventEmitter<any> ();

  @Input() commandMap!: AbstractIndexCommands<any>;

  @Input() displayedColumns: string[] = [];

  constructor(
    protected override store: Store<StorageLocationPartialState>,
    private fb: FormBuilder,
    private actionsSubject: ActionsSubject,
    public authService: AuthService,
    private notificationService: NotificationService,
  ) {
    super(store);
  }

  override ngOnInit() {
    super.ngOnInit();

    if (this.displayedColumns.includes('buyStock')) {
      this.dataSource.connect().subscribe((articleStorageLocations) => {
        for (const articleStorageLocation of (articleStorageLocations as unknown as StorageLocationArticle[])) {
          if (articleStorageLocation.id) {
            this.buyStockForm.removeControl(articleStorageLocation.id + '');

            const formGroup = this.fb.group({
              articleStorageLocation,
              amount: null,
            });

            if (articleStorageLocation.actualStock === 0) {
              formGroup.get('amount')?.disable();
            } else {
              formGroup.get('amount')?.enable();
            }

            console.log(articleStorageLocation.storageLocation.label, articleStorageLocation.actualStock);

            this.buyStockForm.addControl(articleStorageLocation.id + '', formGroup);
          }
        }

        console.log(articleStorageLocations, this.buyStockForm.controls);
      });

      this.actionsSubject.pipe(
        untilDestroyed(this),
        ofType(addItemsToCartSuccess)
      ).subscribe(() => this.buyStockForm.reset());
    }

    this.takeStockInputs.pipe(
      untilDestroyed(this)
    )
      .subscribe(takeStockInputs => this.inputChanged.next(takeStockInputs));

    this.takeStockNotes.pipe(
      untilDestroyed(this)
    )
      .subscribe(takeStockNotes => this.notesChanged.next(takeStockNotes));
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows     = this.dataSource.data.length;
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected () ?
      this.selection.clear () :
      this.dataSource.data.forEach ( row => this.selection.select ( row ) );
  }

  new() {
    this.addEntityClick.emit();
  }

  delete(entity: any) {
    this.deleteEntityClick.emit(entity);
  }

  entityClicked(event: any) {
    this.entityRowClick.emit(event);
  }

  changeStockInputToNextIntervalStep(samplingStep: number, rowId: number) {
    const stockInput = this.buyStockForm.get([rowId, 'amount']);

    if (stockInput) {
      const targetNumberValue = parseInt(stockInput.value, 10);

      if (targetNumberValue % samplingStep !== 0) {
        stockInput.patchValue(calculateNextIntervalStep(stockInput.value, samplingStep));
      }
    }
  }

  buyStocks() {
    if (this.buyStockForm.valid) {
      const buyStockInputs = this.buyStockForm.value;
      const submitBuyStocks: { storageLocationArticleId: number; amount: number; }[] = [];

      Object.keys(buyStockInputs).forEach(storageLocationArticleId => {
        if (buyStockInputs[storageLocationArticleId].amount && buyStockInputs[storageLocationArticleId].amount > 0) {
          submitBuyStocks.push({
            storageLocationArticleId: parseInt(storageLocationArticleId, 10),
            amount: buyStockInputs[storageLocationArticleId].amount
          });
        }
      });

      if (submitBuyStocks.length > 0) {
        this.store.dispatch(addItemsToCart({items: submitBuyStocks}))
      } else {
        this.notificationService.createNotification({
          title: 'Kein Artikel ausgewählt',
          text: 'Es muss mindestens eine Einheiten von einem Artikel ausgewählt werden.',
          status: 'error',
          dismissed: 0,
        })
      }
    }
  }

  buyStock(index: number) {
    const storageLocationArticle = this.dataSource.connect().value[index] as any;

    const item = { storageLocationArticleId: storageLocationArticle.id, amount: this.buyStockInputs[index] };
    console.log(item);

    // this.store.dispatch(addItemToCart({ item }));
  }

  private getChangedItemsInTrunk() {
    let index = 0;
    const changedItems: { trunkArticleId: number, amount: number, note?: string }[] = [];

    for (const takeInput of this.takeStockInputs.value) {
      console.log(takeInput, index);
      if (takeInput) {
        const trunkArticle = this.dataSource.connect().value[index] as any;

        const item: { trunkArticleId: number, amount: number, note?: string } = {
          trunkArticleId: trunkArticle.id,
          amount: this.takeStockInputs.value[index]
        };

        if (this.takeStockNotes.value[index]) {
          item.note = this.takeStockNotes.value[index];
        }

        changedItems.push(item);
      }

      index++;
    }

    return changedItems;
  }

  takeFromTrunk() {
    const changedItems = this.getChangedItemsInTrunk();

    this.takeStockInputs.next([]);
    this.takeStockNotes.next([]);

    this.store.dispatch(changeItemsInTrunk({ changedItems }));
  }

  takeAllFromTrunk() {
    let i = 0;
    const trunkArticles = this.dataSource.connect().value as any;

    for (const trunkArticle of trunkArticles) {
      this.takeStockInputs.value[i] = trunkArticle.availableStock;

      i++;
    }
  }

  closeTrunk() {
    this.closeTrunkClick.emit();

    const withdrawnStockChanges = this.getChangedItemsInTrunk();

    this.store.dispatch(closeTrunk({ withdrawnStockChanges }));
  }

  toggleExpand(element: any) {
    const elementIndex = this.expandedElements.findIndex(expandedElement => expandedElement.id === element.id);

    if (elementIndex > -1) {
      this.expandedElements.splice(elementIndex, 1);
      this.allRowsExpanded = false;
    } else {
      this.expandedElements.push(element);

      if (this.expandedElements.length === this.dataSource.connect().value.length) {
        this.allRowsExpanded = true;
      }
    }
  }

  toggleAllExpanded() {
    if (this.expandedElements.length === this.dataSource.connect().value.length) {
      this.expandedElements = [];
      this.allRowsExpanded = false;
    } else {
      this.expandedElements = [...this.dataSource.connect().value];
      this.allRowsExpanded = true;
    }
  }
}
