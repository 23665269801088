import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {FormControl} from "@angular/forms";
import {MatTable} from "@angular/material/table";
import {MatSort, SortDirection} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {debounceTime, distinctUntilChanged, map, Observable, take} from "rxjs";
import {select, Store} from "@ngrx/store";
import {IndexDataSource} from "./index-datasource";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {AbstractIndexCommands} from "./abstract-index-commands";

@UntilDestroy()
@Component({
  selector: 'knust-abstract-index',
  template: '',
  styles: ['']
})
export abstract class AbstractIndexComponent<T, U extends object> implements OnInit, AfterViewInit {
  @ViewChild(MatTable) table!: MatTable<T>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  searchCtrl = new FormControl('');

  abstract commandMap: AbstractIndexCommands<any>;

  dataSource!: IndexDataSource<T>;
  total?: Observable<number>;
  pageIndex = 0;
  pageSize = 0;
  matSortActive = '';
  matSortDirection!: SortDirection;
  loading?: Observable<boolean>;

  abstract displayedColumns?: string[];

  protected constructor(protected store: Store<U>) {}

  ngOnInit() {
    this.dataSource = new IndexDataSource<T>(this.store, this.commandMap.getAll);
    this.total = this.store.pipe(select(this.commandMap.getTotal));

    // Set initial values from store
    this.store.pipe(
      select(this.commandMap.getPage),
      take(1),
      untilDestroyed(this)
    )
      .subscribe(page => {
        this.pageIndex = page.pageIndex;
        this.pageSize = page.pageSize;
      });

    this.store.pipe(
      select(this.commandMap.getTerm),
      take(1),
      untilDestroyed(this)
    )
      .subscribe(term => {
        this.searchCtrl.setValue(term, { emitEvent: false });
      });

    this.store.pipe(
      select(this.commandMap.getSort),
      take(1),
      untilDestroyed(this)
    )
      .subscribe(sort => {
        if (sort) {
          this.matSortActive = sort.active;
          this.matSortDirection = sort.direction;
        }
      });
  }

  ngAfterViewInit() {
    // Link the control elements to the store and then the datasource to the table
    this.paginator.page.pipe(
      untilDestroyed(this)
    )
      .subscribe(page => {
        this.store.dispatch(this.commandMap.setPage({ page }));
      });

    this.searchCtrl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      untilDestroyed(this)
    )
      .subscribe(term => {
        this.store.dispatch(this.commandMap.setTerm({ term: term ?? '' }));
      });

    this.sort.sortChange.pipe(
      map(val => (val.direction ? val : null)),
      untilDestroyed(this)
    )
      .subscribe((sort) => {
        this.store.dispatch(this.commandMap.setSort({ sort }));
      });

    // this.store.dispatch(this.commandMap.load());

    this.table.dataSource = this.dataSource;
  }
}
