import {Component} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {
  createVendorArticle,
  deleteVendorArticle,
  loadVendorArticles,
  updateVendorArticle
} from "./+state/vendor-article.actions";
import {UserEnum, VendorArticle} from "@knust/api-interfaces";
import {VendorArticleDialogComponent} from "./vendor-article-dialog/vendor-article-dialog.component";
import {getAllVendorArticle} from "./+state/vendor-article.selectors";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {
  vendorArticlesColumnConfig,
  vendorArticlesCommandMap,
  vendorArticlesDisplayedColumns
} from "./vendor-articles-table";
import {AuthService} from "../../utility/services/auth.service";
import {ConfirmComponent} from "../confirm/confirm.component";

@UntilDestroy()
@Component({
  selector: 'knust-vendor-articles',
  templateUrl: './vendor-articles.component.html',
  styleUrls: ['./vendor-articles.component.scss'],
})
export class VendorArticlesComponent {
  isFirstVendorArticle = false;

  commandMap = vendorArticlesCommandMap;
  displayedColumns = vendorArticlesDisplayedColumns;
  columnConfig = vendorArticlesColumnConfig;

  constructor(private dialog: MatDialog,
              private authService: AuthService,
              private store: Store) {
    this.store.dispatch(loadVendorArticles());

    this.store.select(getAllVendorArticle).pipe(
      untilDestroyed(this)
    ).subscribe(vendorArticles => {
      console.log('Got Vendor Articles', vendorArticles);
      this.isFirstVendorArticle = vendorArticles.length === 0;
    });

    if (authService.currentUser.value.type === UserEnum.ADMIN) {
      this.displayedColumns = [...this.displayedColumns, 'deleteAction'];
    }
  }

  openVendorArticleDialog(vendorArticle?: VendorArticle) {
    console.log(vendorArticle);
    this.dialog.open(VendorArticleDialogComponent, {
      data: {
        vendorArticle,
        isFirstVendorArticle: this.isFirstVendorArticle
      },
      width: '40vw',
    })
      .afterClosed()
      .subscribe(vendorArticle => {
        if (vendorArticle && !vendorArticle.id) {
          this.store.dispatch(createVendorArticle( { vendorArticle } ));
        } else if (vendorArticle && vendorArticle.id) {
          this.store.dispatch(updateVendorArticle( { vendorArticle }));
        }
      });
  }

  deleteVendorArticle(vendorArticle: VendorArticle) {
    this.dialog.open(ConfirmComponent, {
      data: {
        title: 'Löschen bestätigen',
        text: 'Möchten Sie diese Lieferanten-Artikel-Beziehung wirklich löschen?',
        buttonColor: 'warn'
      }
    })
      .afterClosed()
      .subscribe(confirm => {
        if (confirm) {
          this.store.dispatch(deleteVendorArticle({ vendorArticle } ));
        }
      })
  }
}
