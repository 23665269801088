import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {Action, createReducer, on} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";

import * as VendorActions from "./vendor.actions";
import {Vendor} from "@knust/api-interfaces";

export const VENDOR_FEATURE_KEY = 'vendor';

export interface State extends EntityState<Vendor> {
  selectedId?: number; // which Vendor record has been selected
  loaded: boolean; // has the Vendor list been loaded
  error?: string | null; // last known error (if any)
  total: number;
  page: PageEvent;
  term: string;
  sort: Sort | null;
}

export interface VendorPartialState {
  readonly [VENDOR_FEATURE_KEY]: State;
}

export const vendorAdapter: EntityAdapter<Vendor> =
  createEntityAdapter<Vendor>();

const initialPaginationState = {
  total: 0,
  page: {
    pageIndex: 0,
    pageSize: 25,
    length: 0
  },
  term: '',
  sort: null
};

export const initialState: State = vendorAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  ...initialPaginationState
});

const vendorReducer = createReducer(
  initialState,
  on(VendorActions.loadVendor, (state) => ({ ...state, loaded: false, error: null })),
  on(VendorActions.loadVendorSuccess, (state, { vendor, total }) =>
    vendorAdapter.setAll(vendor, { ...state, loaded: true, total })
  ),
  on(VendorActions.loadVendorFailure, (state, { error }) => ({ ...state, error })),
  on(VendorActions.setSelectedVendor, (state, { id }) => ({ ...state, selectedId: id })),
  on(VendorActions.loadVendorDetailSuccess, (state, { vendor }) =>
    vendorAdapter.setAll([vendor], { ...state, loaded: false, ...initialPaginationState })
  ),
  on(VendorActions.loadVendorDetailFailure, (state, { error }) => ({ ...state, error })),
  on(VendorActions.setVendorPage, (state, { page }) => ({ ...state, page })),
  on(VendorActions.setVendorTerm, (state, { term }) => ({ ...state, term })),
  on(VendorActions.setVendorSort, (state, { sort }) => ({ ...state, sort })),
  on(VendorActions.clearVendor, (state) =>
    vendorAdapter.removeAll({ ...state, ...initialPaginationState })
  ),
  on(VendorActions.saveVendorSuccess, (state, { vendor, insert }) =>
    vendorAdapter.upsertOne(vendor, { ...state, total: (insert ? state.total + 1 : state.total) })
  ),
  on(VendorActions.saveVendorFailure, (state, { error }) => ({ ...state, error })),
  on(VendorActions.deleteVendorSuccess, (state, { id }) =>
    vendorAdapter.removeOne(id + '', { ...state, total: state.total - 1 })
  ),
  on(VendorActions.deleteVendorFailure, (state, { error }) => ({ ...state, error })),
  on(VendorActions.restoreVendorSuccess, (state, { vendor }) =>
    vendorAdapter.removeOne(vendor.id, { ...state, total: state.total - 1 })
  ),
  on(VendorActions.restoreVendorFailure, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return vendorReducer(state, action);
}
