import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {AuthGuard} from "@knust/core";

const routes: Routes = [
  {
      path: 'login',
      loadChildren: () => import('@knust/core').then ( m => m.LoginModule ),
  },
  {
    path: 'entnahmen',
    loadChildren: () => import('@knust/core').then ( m => m.TrunksModule ),
    canActivate: [AuthGuard]
  },
  {
    path: 'benutzer',
    loadChildren: () => import('@knust/core').then ( m => m.UsersModule ),
    canActivate: [AuthGuard]
  },
  {
    path: 'stammdaten',
    loadChildren: () => import('@knust/core').then ( m => m.BaseDataModule ),
    canActivate: [AuthGuard]
  },
  {
    path: 'lieferanten',
    loadChildren: () => import('@knust/core').then ( m => m.VendorsModule ),
    canActivate: [AuthGuard]
  },
  {
    path: 'artikel',
    loadChildren: () => import('@knust/core').then (m => m.ArticlesModule ),
    canActivate: [AuthGuard]
  },
  {
    path: 'wareneingangsbuchung',
    loadChildren: () => import('@knust/core').then (m => m.DeliveryModule ),
    canActivate: [AuthGuard]
  },
  {
    path: 'papierkorb',
    loadChildren: () => import('@knust/core').then (m => m.TrashModule ),
    canActivate: [AuthGuard]
  },
  {
    path: 'import',
    loadChildren: () => import('@knust/core').then (m => m.ImportModule ),
    canActivate: [AuthGuard]
  },
  {
    path: 'berichte',
    loadChildren: () => import('@knust/core').then (m => m.ReportsModule ),
    canActivate: [AuthGuard]
  },
  {
      path: '**',
      redirectTo: 'artikel',
      pathMatch: 'full',
  },
];

@NgModule ( {
    imports: [RouterModule.forRoot ( routes )],
    exports: [RouterModule],
} )
export class AppRoutingModule {
}
