import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";

import {ImportRoutingModule} from "./import-routing.module";
import {ImportComponent} from "./import.component";
import {ReactiveFormsModule} from "@angular/forms";
import {DataTableModule} from "../reusable-components/data-table/data-table.module";
import {StoreModule} from "@ngrx/store";
import * as fromImportArticle from "./+state/import-article.reducer";
import {EffectsModule} from "@ngrx/effects";
import {ImportArticleEffects} from "./+state/import-article.effects";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [ImportComponent],
    imports: [
        CommonModule,
        ImportRoutingModule,
        ReactiveFormsModule,
        DataTableModule,
        StoreModule.forFeature(
            fromImportArticle.IMPORT_ARTICLE_FEATURE_KEY,
            fromImportArticle.reducer
        ),
        EffectsModule.forFeature([ImportArticleEffects]),
        MatButtonModule,
        MatIconModule,
    ],
})
export class ImportModule {}
