import {Injectable} from "@angular/core";
import {catchError, filter, map, of, switchMap, tap} from "rxjs";
import {Store} from "@ngrx/store";
import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";

import * as VendorActions from "./vendor.actions";
import {getSelected, getSelectedId, getVendorQuery} from "./vendor.selectors";
import {VendorsIndexComponent} from "../vendors-index/vendors-index.component";
import {VendorsDetailComponent} from "../vendors-detail/vendors-detail.component";
import {onNavigation} from "../../+state/on-navigation.operator";
import {NEW_ENTITY} from "../../utility/constants/new-entity.constants";
import {VendorService} from "../../utility/services/vendor.service";
import {Router} from "@angular/router";
import {TrashComponent} from "../../trash/trash.component";
import {getTrashState} from "../../+state/root.selectors";
import {StoreRootState} from "../../+state/root.reducer";

@Injectable()
export class VendorEffects {
  vendorIndex$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(VendorsIndexComponent),
      map( () => VendorActions.loadVendor())
    )
  );

  vendorTrash$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(TrashComponent),
      map( () => VendorActions.loadVendor())
    )
  );

  vendorView$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(VendorsDetailComponent),
      tap(() => console.log('Loading Vendor Details')),
      filter( action => action.payload.routerState.params['vendorId'] !== NEW_ENTITY),
      map( (action) => {
        console.log('Loading Vendor Step 2');
        const vendorId = parseInt(action.payload.routerState.params['vendorId'], 10);

        if (!vendorId) {
          throw new Error('vendor id is missing');
        }

        return VendorActions.setSelectedVendor({ id: vendorId });
      })
    )
  );

  selectVendor$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VendorActions.setSelectedVendor),
        concatLatestFrom(() => this.store.select(getSelected)),
        switchMap(([action, selected]) => {
          if (!selected) {
            console.log('Not selected - loading!')
            return this.vendorService.loadVendorDetails(action.id).pipe(
              map(res => VendorActions.loadVendorDetailSuccess({ vendor: res }))
            );
          }

          return of(VendorActions.loadVendorDetailUnneeded());
        }),
        catchError((error) => {
          return of(VendorActions.loadVendorFailure({ error }));
        })
      )
  );

  loadVendor$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VendorActions.loadVendor),
        concatLatestFrom(() => [
          this.store.select(getVendorQuery),
          this.store.select(getTrashState)
        ]),
        switchMap(([action, query, trashState]) => {
          return this.vendorService.loadVendors('/' + query + '&deleted=' + trashState).pipe(
            tap(() => console.log('Store Load Complete')),
            map(res => VendorActions.loadVendorSuccess({ vendor: res.items, total: res.meta.itemCount })),
          );
        }),
        catchError((error) => {
          return of(VendorActions.loadVendorFailure({ error }));
        })
      )
  );

  setVendorFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        VendorActions.setVendorPage,
        VendorActions.setVendorTerm,
        VendorActions.setVendorSort
      ),
      map(() => VendorActions.loadVendor())
    )
  );

  createVendor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VendorActions.createVendor),
      switchMap((action) => {
        return this.vendorService.saveVendor(action.vendor).pipe(
          map(created =>
            VendorActions.saveVendorSuccess( { vendor: created, insert: true })
          ),
          catchError(error => {
            return of(VendorActions.saveVendorFailure({ error }));
          })
        )
      })
    )
  );

  updateVendor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VendorActions.updateVendor),
      switchMap((action) => {
        return this.vendorService.saveVendor(action.vendor).pipe(
          map(updated =>
            VendorActions.saveVendorSuccess( { vendor: updated, insert: false })
          ),
          catchError(error => {
            return of(VendorActions.saveVendorFailure({ error }));
          })
        )
      })
    )
  );

  deleteVendor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VendorActions.deleteVendor),
      concatLatestFrom(() => this.store.select(getSelectedId)),
      switchMap(([action, vendorId]) => {
        if (vendorId) {
          return this.vendorService.deleteVendor(vendorId).pipe(
            map((response) =>
              VendorActions.deleteVendorSuccess( { id: response.id })
            ),
            catchError(error => {
              return of(VendorActions.deleteVendorFailure({ error }));
            })
          )
        }

        return of(VendorActions.deleteVendorFailure({ error: 'Kein Lieferant ausgewählt.' }));
      })
    )
  );

  restoreVendor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VendorActions.restoreVendor),
      switchMap((action) => {
        return this.vendorService.restoreVendor(action.element).pipe(
          map((response) =>
            VendorActions.restoreVendorSuccess( { vendor: response })
          ),
          catchError(error => {
            return of(VendorActions.restoreVendorFailure({ error }));
          })
        )
      })
    )
  );

  saveVendorSuccess$ = createEffect(() =>this.actions$.pipe(
      ofType(
        VendorActions.saveVendorSuccess
      ),
      filter(action => action.insert),
      tap( action => {
        console.log('Starting Navigation', action.vendor);
        this.router.navigate(['lieferanten', action.vendor.id]);
      })
    ), {dispatch: false}
  );

  deleteVendorSuccess$ = createEffect(() =>this.actions$.pipe(
      ofType(
        VendorActions.deleteVendorSuccess
      ),
      tap( () => {
        this.router.navigate(['lieferanten']);
      })
    ), {dispatch: false}
  );

  constructor(private readonly actions$: Actions,
              private vendorService: VendorService,
              private router: Router,
              private store: Store<StoreRootState>) {
  }
}
