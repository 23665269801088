import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, filter, Observable, take} from "rxjs";
import {PaginatedResponse} from "../interfaces/paginated-response";
import {User} from "@knust/api-interfaces";
import {USER_URL} from "../constants/api.constants";
import {AuthService} from "./auth.service";
import {AuthStatus} from "../enums/auth-status";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public allUsers: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.currentStatus
      .pipe(
        filter(status => status === AuthStatus.LOGGED_IN),
        take(1)
      )
      .subscribe(() => {
        this.loadUsers().subscribe(users => this.allUsers.next(users.items));
      });
  }

  loadUsers(query?: string): Observable<PaginatedResponse<User>> {
    return this.http.get<PaginatedResponse<User>>(USER_URL + (query ?? ''));
  }

  saveUser(user: User | Omit<User, 'id'>): Observable<User> {
    return this.http.post<User>(USER_URL, user);
  }

  deleteUser(user: User) {
    return this.http.delete(USER_URL + '/' + user.id);
  }

  restoreUser(user: User): Observable<User> {
    return this.http.patch<User>(USER_URL + '/' + user.id, {});
  }
}
