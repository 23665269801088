import {AbstractIndexCommands} from "../utility/abstract-index/abstract-index-commands";
import {Article} from "@knust/api-interfaces";
import {
  getAllArticle,
  getArticlePage,
  getArticleSort,
  getArticleTerm,
  getArticleTotal
} from "./+state/article.selectors";
import {
  createArticle,
  deleteArticle,
  loadArticle,
  restoreArticle,
  setArticlePage,
  setArticleSort,
  setArticleTerm
} from "./+state/article.actions";
import {ColumnConfig} from "../reusable-components/data-table/column-config";

export const articleDisplayedColumns = ['photo', 'internalId', 'title', 'productCategory.label', 'buyShortcut'];
export const articleColumnConfig: ColumnConfig[] = [
  {
    name: 'Artikelbild',
    refName: 'photo',
    type: 'image',
    disableSort: true,
  },
  {
    name: 'Artikelnummer',
    refName: 'internalId'
  },
  {
    name: 'Name',
    refName: 'title'
  },
  {
    name: 'Kategorie',
    refName: 'productCategory',
    nestedName: ['label'],
    useNestedName: true,
    fallback: 'Nicht zugeordnet'
  },
  {
    name: 'Hauptlieferant',
    refName: 'mainVendor',
    nestedName: ['organization', 'name'],
    useNestedName: true,
    fallback: 'Nicht gesetzt'
  },
  {
    name: 'Preis',
    refName: 'mainVendorArticle',
    nestedName: ['price'],
    useNestedName: true,
    fallback: '0',
    type: 'currency'
  },
  {
    name: 'Beschreibung',
    refName: 'description'
  },
  {
    name: 'Lagerbestand',
    refName: 'storageStatus',
    type: 'textToColor'
  }
];

export const articleCommandMap: AbstractIndexCommands<Article> = {
  getAll: getAllArticle,
  getTotal: getArticleTotal,
  getPage: getArticlePage,
  setPage: setArticlePage,
  getTerm: getArticleTerm,
  setTerm: setArticleTerm,
  getSort: getArticleSort,
  setSort: setArticleSort,
  load: loadArticle,
  createElement: createArticle,
  deleteElement: deleteArticle,
  restoreElement: restoreArticle
};
