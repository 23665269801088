import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {Action, createReducer, on} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";

import * as ArticleActions from "./article.actions";
import {Article} from "@knust/api-interfaces";

export const ARTICLE_FEATURE_KEY = 'article';

export interface State extends EntityState<Article> {
  selectedId?: number; // which Article record has been selected
  loaded: boolean; // has the Article list been loaded
  error?: string | null; // last known error (if any)
  total: number;
  page: PageEvent;
  term: string;
  category: number;
  sort: Sort | null;
}

export interface ArticlePartialState {
  readonly [ARTICLE_FEATURE_KEY]: State;
}

export const articleAdapter: EntityAdapter<Article> =
  createEntityAdapter<Article>();

const initialPaginationState = {
  total: 0,
  page: {
    pageIndex: 0,
    pageSize: 25,
    length: 0
  },
  term: '',
  sort: null,
  category: 0,
};

export const initialState: State = articleAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  ...initialPaginationState
});

const articleReducer = createReducer(
  initialState,
  on(ArticleActions.loadArticle, (state) => ({ ...state, loaded: false, error: null })),
  on(ArticleActions.loadArticleSuccess, (state, { article, total }) =>
    articleAdapter.setAll(article, { ...state, loaded: true, total })
  ),
  on(ArticleActions.setCategoryFilter, (state, { categoryId }) => ({ ...state, category: categoryId })),
  on(ArticleActions.loadArticleFailure, (state, { error }) => ({ ...state, error })),
  on(ArticleActions.setSelectedArticle, (state, { id }) => ({ ...state, selectedId: id })),
  on(ArticleActions.unsetSelectedArticle, (state) => ({ ...state, selectedId: undefined })),
  on(ArticleActions.loadArticleDetailSuccess, (state, { article }) =>
    articleAdapter.setAll([article], { ...state, loaded: false, ...initialPaginationState })
  ),
  on(ArticleActions.loadArticleDetailFailure, (state, { error }) => ({ ...state, error })),
  on(ArticleActions.setArticlePage, (state, { page }) => ({ ...state, page })),
  on(ArticleActions.setArticleTerm, (state, { term }) => ({ ...state, term })),
  on(ArticleActions.setArticleSort, (state, { sort }) => ({ ...state, sort })),
  on(ArticleActions.clearArticle, (state) =>
    articleAdapter.removeAll({ ...state, ...initialPaginationState })
  ),
  on(ArticleActions.saveArticleSuccess, (state, { article, insert }) =>
    articleAdapter.upsertOne(article, { ...state, total: (insert ? state.total + 1 : state.total) })
  ),
  on(ArticleActions.saveArticleFailure, (state, { error }) => ({ ...state, error })),
  on(ArticleActions.deleteArticleSuccess, (state, { id }) =>
    articleAdapter.removeOne(id + '', { ...state, total: state.total - 1 })
  ),
  on(ArticleActions.deleteArticleFailure, (state, { error }) => ({ ...state, error })),
  on(ArticleActions.restoreArticleSuccess, (state, { article }) =>
    articleAdapter.removeOne(article.id, { ...state, total: state.total - 1 })
  ),
  on(ArticleActions.restoreArticleFailure, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return articleReducer(state, action);
}
