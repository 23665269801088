import {Component} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {API_BASE_URL, STORAGE_LOCATION_ARTICLES_URL} from "../utility/constants/api.constants";
import {Article, ImportArticle} from "@knust/api-interfaces";
import {Router} from "@angular/router";
import {
  importArticleColumnConfig,
  importArticleCommandMap,
  importArticleDisplayedColumns
} from "./import-article-table";
import {ConfirmComponent} from "../reusable-components/confirm/confirm.component";
import {MatDialog} from "@angular/material/dialog";
import {deleteImportArticle, loadImportArticle} from "./+state/import-article.actions";
import {Store} from "@ngrx/store";
import {StorageLocationArticleService} from "../utility/services/storage-location-article.service";

@Component({
  selector: 'knust-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
})
export class ImportComponent {
  selectedFile = undefined;
  commandMap = importArticleCommandMap;
  displayedColumns = importArticleDisplayedColumns;
  columnConfig = importArticleColumnConfig;

  constructor(private http: HttpClient,
              private dialog: MatDialog,
              private store: Store,
              private storageLocationArticleService: StorageLocationArticleService,
              private router: Router) {}

  selectFile(event: any): void {
    this.selectedFile = event.target.files[0];
  }

  upload() {
    if (this.selectedFile) {
      const fd = new FormData();
      fd.append('file', this.selectedFile);

      this.http.post(API_BASE_URL + '/import/upload', fd).subscribe(
        () => {
          this.store.dispatch(loadImportArticle());
        }
      );
    }
  }

  navigateToArticleEditing(article: Article) {
    this.router.navigate(['/', 'artikel', 'neu'], { state: { article }});
  }

  deleteImportArticle(article: ImportArticle) {
    this.dialog.open(ConfirmComponent, {
      data: {
        title: 'Importierten Artikel wirklich löschen?',
        text: 'Möchten Sie diesen importierten Artikel wirklich löschen?',
        buttonColor: 'warn'
      }
    })
      .afterClosed()
      .subscribe(confirm => {
        if (confirm) {
          this.store.dispatch(deleteImportArticle({ id: article.id }));
        }
      })
  }

  exportArticles() {
    this.storageLocationArticleService.exportStorageLocationArticles().subscribe(downloadObject => {
      const a = document.createElement("a");
      a.href = STORAGE_LOCATION_ARTICLES_URL + '/export/' + downloadObject.filePath;
      const filename = "artikel-export";
      a.setAttribute("download", filename + ".csv");
      a.setAttribute("target", "_blank");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  }
}
