import {Component} from "@angular/core";
import {FormBuilder, Validators} from "@angular/forms";
import {AuthService} from "../../utility/services/auth.service";
import {ErrorResponse} from "../../utility/interfaces/error-response";
import {catchError, combineLatestWith, filter, of} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthStatus} from "../../utility/enums/auth-status";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {LOGO_URL} from "../../utility/constants/api.constants";

@UntilDestroy()
@Component({
  selector: 'knust-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  logoPath = LOGO_URL;

  loginForm = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });

  error: ErrorResponse | undefined = undefined;

  constructor(private fb: FormBuilder,
              public auth: AuthService,
              private router: Router,
              private route: ActivatedRoute) {
    this.auth.currentStatus.pipe(
      untilDestroyed(this),
      filter(status => status === AuthStatus.LOGGED_IN),
      combineLatestWith(auth.appInitCompleted)
    ).subscribe(([status, appInitCompleted]) => {
      const previousPage = this.route.snapshot.queryParamMap.get('prev');

      console.log(appInitCompleted);
      if (appInitCompleted) {
        this.router.navigate([previousPage ?? '']);
      } else {
        this.router.navigate(['stammdaten']);
      }
    });
  }

  login() {
    const { username, password } = this.loginForm.value;

    if (username && password) {
      this.auth.login(username, password).pipe(
        catchError(err => {
          this.error = err.error;
          return of(err);
        }),
        untilDestroyed(this),
      ).subscribe();
    }
  }

  register() {
    const { username, password } = this.loginForm.value;

    if (username && password) {
      this.auth.register(username, password).pipe(
        untilDestroyed(this),
      ).subscribe();
    }
  }
}
