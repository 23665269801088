import {Injectable} from "@angular/core";
import {catchError, filter, map, of, switchMap, tap} from "rxjs";
import {Store} from "@ngrx/store";
import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";

import * as CategoryActions from "./category.actions";
import {getCategoryQuery, getSelected} from "./category.selectors";
import {NEW_ENTITY} from "../../../utility/constants/new-entity.constants";
import {onNavigation} from "../../../+state/on-navigation.operator";
import {CategoriesComponent} from "../categories.component";
import {CategoryService} from "../../../utility/services/category.service";
import {TrashComponent} from "../../../trash/trash.component";
import {getTrashState} from "../../../+state/root.selectors";
import {StoreRootState} from "../../../+state/root.reducer";

@Injectable()
export class CategoryEffects {
  categoryIndex$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(CategoriesComponent),
      map( () => CategoryActions.loadCategories())
    )
  );

  categoryTrash$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(TrashComponent),
      map( () => CategoryActions.loadCategories())
    )
  );

  categoryView$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(CategoriesComponent),
      filter( action => action.payload.routerState.params['categoryId'] !== NEW_ENTITY),
      map( (action) => {
        const categoryId = parseInt(action.payload.routerState.params['categoryId'], 10);

        if (!categoryId) {
          throw new Error('category id is missing');
        }

        return CategoryActions.setSelectedCategory({ id: categoryId });
      })
    )
  );

  selectCategory$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CategoryActions.setSelectedCategory),
        concatLatestFrom(() => this.store.select(getSelected)),
        switchMap(([action, selected]) => {
          if (!selected) {
            return this.categoryService.loadCategories().pipe(
              map(res => CategoryActions.loadCategoryDetailSuccess({ category: res.items[0] }))
            );
          }

          return of(CategoryActions.loadCategoryDetailUnneeded());
        }),
        catchError((error) => {
          return of(CategoryActions.loadCategoriesFailure({ error }));
        })
      )
  );

  loadCategory$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CategoryActions.loadCategories),
        concatLatestFrom(() => [
          this.store.select(getCategoryQuery),
          this.store.select(getTrashState)
        ]),
        switchMap(([action, query, trashState]) => {
          return this.categoryService.loadCategories(query + '&deleted=' + trashState).pipe(
            tap(() => console.log('Store Load Complete')),
            map(res => CategoryActions.loadCategoriesSuccess({ category: res.items, total: res.meta.itemCount })),
          );
        }),
        catchError((error) => {
          return of(CategoryActions.loadCategoriesFailure({ error }));
        })
      )
  );

  setCategoryFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CategoryActions.setCategoryPage,
        CategoryActions.setCategoryTerm,
        CategoryActions.setCategorySort
      ),
      map(() => CategoryActions.loadCategories())
    )
  );

  createCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.createCategory),
      switchMap((action) => {
        return this.categoryService.saveCategory(action.category).pipe(
          map(created =>
            CategoryActions.saveCategorySuccess( { category: created, insert: true })
          ),
          catchError(error => {
            return of(CategoryActions.saveCategoryFailure({ error }));
          })
        )
      })
    )
  );

  updateCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.updateCategory),
      switchMap((action) => {
        return this.categoryService.saveCategory(action.category).pipe(
          map(updated =>
            CategoryActions.saveCategorySuccess( { category: updated, insert: false })
          ),
          catchError(error => {
            return of(CategoryActions.saveCategoryFailure({ error }));
          })
        )
      })
    )
  );

  deleteCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.deleteCategory),
      switchMap((action) => {
        return this.categoryService.deleteCategory(action.category.id).pipe(
          map(() =>
            CategoryActions.deleteCategorySuccess( { id: action.category.id })
          ),
          catchError(error => {
            return of(CategoryActions.deleteCategoryFailure({ error }));
          })
        )
      })
    )
  );

  restoreCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.restoreCategory),
      switchMap((action) => {
        return this.categoryService.restoreCategory(action.element).pipe(
          map(response =>
            CategoryActions.restoreCategorySuccess( { category: response })
          ),
          catchError(error => {
            return of(CategoryActions.restoreCategoryFailure({ error }));
          })
        )
      })
    )
  );

  constructor(private readonly actions$: Actions,
              private categoryService: CategoryService,
              private store: Store<StoreRootState>) {
  }
}
