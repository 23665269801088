import {createAction, props} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";
import {Trunk, TrunkArticle, TrunkBooking} from "@knust/api-interfaces";

export const loadCart = createAction('[Trunk] Load Cart');

export const loadCartSuccess = createAction(
  '[Trunk/API] Load Cart Success',
  props<{ cart: Trunk }>()
);

export const loadCartFailure = createAction(
  '[Trunk/API] Load Cart Failure',
  props<{ error: any }>()
);

export const addItemsToCart = createAction(
  '[Trunk/API] Add Items to Cart',
  props<{ items: { storageLocationArticleId: number; amount: number; }[] }>()
)

export const addItemsToCartSuccess = createAction(
  '[Trunk/API] Add Item to Cart Success',
  props<{ cart: Trunk }>()
);

export const addItemsToCartFailure = createAction(
  '[Trunk/API] Add Item to Cart Failure',
  props<{ error: any }>()
);

export const changeItemInCart = createAction(
  '[Trunk/API] Change Item in Cart',
  props<{ withdrawnStockChanges: { trunkArticleId: number, amount: number; note?: string }[], note: string | null }>()
);

export const changeItemInCartSuccess = createAction(
  '[Trunk/API] Change Item in Cart Success',
  props<{ trunkArticles: TrunkArticle[], note: string | null }>()
);

export const changeItemInCartFailure = createAction(
  '[Trunk/API] Change Item in Cart Failure',
  props<{ error: any }>()
);

export const deleteItemFromCart = createAction(
  '[Trunk/API] Delete Item from Cart',
  props<{ trunkArticleId: number }>()
);

export const deleteItemFromCartSuccess = createAction(
  '[Trunk/API] Delete Item from Cart Success',
  props<{ trunkArticleId: number }>()
);

export const closeCart = createAction(
  '[Trunk/API] Close Cart',
  props<{ withdrawnStockChanges: { trunkArticleId: number; amount: number; }[], note: string | null }>()
);

export const closeCartSuccess = createAction(
  '[Trunk/API] Close Cart Success',
  props<{ cart: Trunk }>()
);

export const closeCartFailure = createAction(
  '[Trunk/API] Close Cart Failure',
  props<{ error: any }>()
);

export const closeTrunk = createAction(
  '[Trunk/API] Close Trunk',
  props<{ withdrawnStockChanges: { trunkArticleId: number; amount: number; }[] }>()
);

export const closeTrunkSuccess = createAction(
  '[Trunk/API] Close Trunk Success',
  props<{ trunk: Trunk }>()
);

export const closeTrunkFailure = createAction(
  '[Trunk/API] Close Trunk Failure',
  props<{ error: any }>()
);

export const loadTrunkArticles = createAction('[Trunk] Load Trunk Articles');

export const loadTrunkArticlesSuccess = createAction(
  '[Trunk/API] Load Trunk Articles Success',
  props<{ trunkArticles: TrunkArticle[], total: number }>()
);

export const loadTrunkArticlesFailure = createAction(
  '[Trunk/API] Load Trunk Articles Failure',
  props<{ error: any }>()
);

export const loadTrunkBookings = createAction('[Trunk] Load Trunk Bookings');

export const loadTrunkBookingsSuccess = createAction(
  '[Trunk/API] Load Trunk Bookings Success',
  props<{ trunkBookings: TrunkBooking[], total: number }>()
);

export const loadTrunkBookingsFailure = createAction(
  '[Trunk/API] Load Trunk Bookings Failure',
  props<{ error: any }>()
);

export const loadTrunk = createAction('[Trunk] Load Trunk');

export const loadTrunkSuccess = createAction(
  '[Trunk/API] Load Trunk Success',
  props<{ trunk: Trunk[], total: number }>()
);

export const loadTrunkFailure = createAction(
  '[Trunk/API] Load Trunk Failure',
  props<{ error: any }>()
);

export const loadTrunkReports = createAction(
  '[Trunk] Load Trunk Reports',
  (payload: { download: boolean } = { download: false }) => payload
);

export const loadTrunkReportsSuccess = createAction(
  '[Trunk/API] Load Trunk Reports Success',
  props<{ trunkReports: Trunk[], total: number }>()
);

export const downloadTrunkReport = createAction(
  '[Trunk/API] Download Trunk Reports',
  props<{ url: string }>()
)

export const loadTrunkReportsFailure = createAction(
  '[Trunk/API] Load Trunk Reports Failure',
  props<{ error: any }>()
);

export const changeCompletedFilter = createAction(
  '[Trunk/API] Set Completed Filter',
)

export const setTrunkReportsUserFilter = createAction(
  '[Trunk/API] Set Reports User Filter',
  props<{ userId: number }>()
)

export const setTrunkReportsArticleFilter = createAction(
  '[Trunk/API] Set Reports Article Filter',
  props<{ articleId: number }>()
)

export const setTrunkReportsDateFilter = createAction(
  '[Trunk/API] Set Reports Date Filter',
  props<{ dateStart: Date | undefined, dateEnd: Date | undefined }>()
)

export const changeTrunkReportsCompletedFilter = createAction(
  '[Trunk/API] Set Reports Completed Filter',
)

export const resetReportFilters = createAction(
  '[Trunk] Reset Report Filters',
);

export const setSelectedTrunk = createAction(
  '[Trunk] Set selected Trunk',
  props<{ id: number }>()
);

export const unsetSelectedTrunk = createAction(
  '[Trunk] Unset selected Trunk',
);

export const loadTrunkDetailUnneeded = createAction(
  '[Trunk/API] Load Trunk Detail Unneeded'
);

export const loadTrunkDetailSuccess = createAction(
  '[Trunk/API] Load Trunk Detail Success',
  props<{ trunk: Trunk }>()
);

export const loadTrunkDetailFailure = createAction(
  '[Trunk/API] Load Trunk Detail Failure',
  props<{ error: any }>()
);

export const changeItemsInTrunk = createAction(
  '[Trunk/API] Change Item in Trunk',
  props<{ changedItems: { trunkArticleId: number, amount: number, note?: string }[] }>()
);

export const changeItemsInTrunkSuccess = createAction(
  '[Trunk/API] Change Item in Trunk Success',
  props<{ trunkArticles: TrunkArticle[] }>()
);

export const changeItemsInTrunkFailure = createAction(
  '[Trunk/API] Change Item in Trunk Failure',
  props<{ error: any }>()
);

export const resetTrunk = createAction(
  '[Trunk/API] Reset Trunk',
);

export const resetTrunkSuccess = createAction(
  '[Trunk/API] Reset Trunk Success',
);

export const resetTrunkFailure = createAction(
  '[Trunk/API] Reset Trunk Failure',
  props<{ error: any }>()
);

export const setTrunkPage = createAction(
  '[Trunk/API] Set Page',
  props<{ page: PageEvent }>()
);

export const setTrunkTerm = createAction(
  '[Trunk/API] Set Term',
  props<{ term: string }>()
);

export const setTrunkSort = createAction(
  '[Trunk/API] Set Sort',
  props<{ sort: Sort | null }>()
);

export const setTrunkArticlesPage = createAction(
  '[Trunk/API] Set Articles Page',
  props<{ page: PageEvent }>()
);

export const setTrunkArticlesTerm = createAction(
  '[Trunk/API] Set Articles Term',
  props<{ term: string }>()
);

export const setTrunkArticlesSort = createAction(
  '[Trunk/API] Set Articles Sort',
  props<{ sort: Sort | null }>()
);

export const setTrunkBookingsPage = createAction(
  '[Trunk/API] Set Bookings Page',
  props<{ page: PageEvent }>()
);

export const setTrunkBookingsTerm = createAction(
  '[Trunk/API] Set Bookings Term',
  props<{ term: string }>()
);

export const setTrunkBookingsSort = createAction(
  '[Trunk/API] Set Bookings Sort',
  props<{ sort: Sort | null }>()
);

export const setTrunkReportsPage = createAction(
  '[Trunk/API] Set Reports Page',
  props<{ page: PageEvent }>()
);

export const setTrunkReportsTerm = createAction(
  '[Trunk/API] Set Reports Term',
  props<{ term: string }>()
);

export const setTrunkReportsSort = createAction(
  '[Trunk/API] Set Reports Sort',
  props<{ sort: Sort | null }>()
);

export const clearTrunk = createAction(
  '[Trunk/API] Clear'
);

export const createTrunk = createAction(
  '[Trunk/API] Create Trunk',
  props<{ trunk: Omit<Trunk, 'id'> }>()
);

export const updateTrunk = createAction(
  '[Trunk/API] Update Trunk',
  props<{ trunk: Trunk }>()
);

export const saveTrunkNote = createAction(
  '[Trunk/API] Save Trunk Note',
  props<{ note: string | null }>()
)

export const saveTrunkNoteSuccess = createAction(
  '[Trunk/API] Save Trunk Note Success',
  props<{ note: string | null }>()
)

export const saveTrunkNoteFailure = createAction(
  '[Trunk/API] Save Trunk Note Failure',
  props<{ error: any }>()
);

export const saveTrunkSuccess = createAction(
  '[Trunk/API] Save Trunk Success',
  props<{ trunk: Trunk, insert: boolean }>()
);

export const saveTrunkFailure = createAction(
  '[Trunk/API] Save Trunk Failure',
  props<{ error: any }>()
);

export const deleteTrunk = createAction(
  '[Trunk/API] Delete Trunk',
  props<{ trunk: Trunk }>()
);

export const deleteTrunkSuccess = createAction(
  '[Trunk/API] Delete Trunk Success',
  props<{ id: number | string }>()
);

export const deleteTrunkFailure = createAction(
  '[Trunk/API] Delete Trunk Failure',
  props<{ error: any }>()
);
