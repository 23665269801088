import {Injectable} from "@angular/core";
import {catchError, filter, map, of, switchMap, tap} from "rxjs";
import {Store} from "@ngrx/store";
import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";

import * as ArticleActions from "./article.actions";
import {getArticleQuery, getCategoryFilter, getSelected, getSelectedId} from "./article.selectors";
import {ArticlesIndexComponent} from "../articles-index/articles-index.component";
import {onNavigation} from "../../+state/on-navigation.operator";
import {NEW_ENTITY} from "../../utility/constants/new-entity.constants";
import {ArticleService} from "../../utility/services/article.service";
import {ArticlesDetailsComponent} from "../articles-details/articles-details.component";
import {Router} from "@angular/router";
import {getTrashState} from "../../+state/root.selectors";
import {StoreRootState} from "../../+state/root.reducer";
import {TrashComponent} from "../../trash/trash.component";

@Injectable()
export class ArticleEffects {
  articleIndex$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(ArticlesIndexComponent),
      map(() => ArticleActions.setCategoryFilter({ categoryId: 0 }))
    )
  );

  articleTrash$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(TrashComponent),
      map( () => ArticleActions.loadArticle())
    )
  );

  unsetArticleOnTrash$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(TrashComponent),
      map( () => ArticleActions.unsetSelectedArticle())
    )
  );

  articleView$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(ArticlesDetailsComponent),
      tap(() => console.log('Loading Article Details')),
      map( (action) => {
        console.log('Loading Article Step 2');
        if (action.payload.routerState.params['articleId'] === NEW_ENTITY) {
          return ArticleActions.unsetSelectedArticle();
        }

        const articleId = parseInt(action.payload.routerState.params['articleId'], 10);

        if (!articleId) {
          throw new Error('article id is missing');
        }

        return ArticleActions.setSelectedArticle({ id: articleId });
      })
    )
  );

  selectArticle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ArticleActions.setSelectedArticle),
        concatLatestFrom(() => this.store.select(getSelected)),
        switchMap(([action, selected]) => {
          if (!selected) {
            console.log('Not selected - loading!')
            return this.articleService.loadArticleDetails(action.id).pipe(
              map(res => ArticleActions.loadArticleDetailSuccess({ article: res }))
            );
          }

          return of(ArticleActions.loadArticleDetailUnneeded());
        }),
        catchError((error) => {
          return of(ArticleActions.loadArticleFailure({ error }));
        })
      )
  );

  loadArticle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ArticleActions.loadArticle),
        concatLatestFrom(() => [
          this.store.select(getArticleQuery),
          this.store.select(getCategoryFilter),
          this.store.select(getTrashState),
        ]),
        switchMap(([action, query, category, trashState]) => {
          return this.articleService.loadArticles('/' + query + '&category=' + category + '&deleted=' + trashState).pipe(
            tap(() => console.log('Store Load Complete')),
            map(res => ArticleActions.loadArticleSuccess({ article: res.items, total: res.meta.itemCount })),
          );
        }),
        catchError((error) => {
          return of(ArticleActions.loadArticleFailure({ error }));
        })
      )
  );

  setArticleFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ArticleActions.setArticlePage,
        ArticleActions.setArticleTerm,
        ArticleActions.setArticleSort,
        ArticleActions.setCategoryFilter,
      ),
      map(() => ArticleActions.loadArticle())
    )
  );

  createArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.createArticle),
      switchMap((action) => {
        return this.articleService.saveArticle(action.article).pipe(
          map(created =>
            ArticleActions.saveArticleSuccess( { article: created, insert: true })
          ),
          catchError(error => {
            return of(ArticleActions.saveArticleFailure({ error }));
          })
        )
      })
    )
  );

  updateArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.updateArticle),
      switchMap((action) => {
        return this.articleService.saveArticle(action.article).pipe(
          map(updated =>
            ArticleActions.saveArticleSuccess( { article: updated, insert: false })
          ),
          catchError(error => {
            return of(ArticleActions.saveArticleFailure({ error }));
          })
        )
      })
    )
  );

  deleteArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.deleteArticle),
      concatLatestFrom(() => this.store.select(getSelectedId)),
      switchMap(([action, articleId]) => {
        if (articleId) {
          return this.articleService.deleteArticle(articleId).pipe(
            map(() =>
              ArticleActions.deleteArticleSuccess( { id: articleId })
            ),
            catchError(error => {
              return of(ArticleActions.deleteArticleFailure({ error }));
            })
          )
        }

        return of(ArticleActions.deleteArticleFailure({ error: 'Es ist kein Artikel ausgewählt.' }));
      })
    )
  );

  restoreArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.restoreArticle),
      switchMap((action) => {
        return this.articleService.restoreArticle(action.element).pipe(
          map(response =>
            ArticleActions.restoreArticleSuccess( { article: response })
          ),
          catchError(error => {
            return of(ArticleActions.restoreArticleFailure({ error }));
          })
        )
      })
    )
  );

  saveArticleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ArticleActions.saveArticleSuccess
      ),
      filter(action => action.insert),
      tap( action => {
        console.log('Starting Navigation', action.article);
        this.router.navigate(['artikel', action.article.id]);
      })
    ), { dispatch: false }
  );

  deleteArticleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ArticleActions.deleteArticleSuccess
      ),
      tap( () => {
        this.router.navigate(['artikel']);
      })
    ), { dispatch: false }
  );

  constructor(private readonly actions$: Actions,
              private articleService: ArticleService,
              private router: Router,
              private store: Store<StoreRootState>) {
  }
}
