import {Injectable} from "@angular/core";
import {catchError, filter, map, of, switchMap, tap} from "rxjs";
import {Store} from "@ngrx/store";
import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";

import * as ArticleStorageLocationActions from "./article-storage-location.actions";
import {getArticleStorageLocationQuery, getEmptyFilter, getSelected} from "./article-storage-location.selectors";
import {ArticleStorageLocationPartialState} from "./article-storage-location.reducer";
import {NEW_ENTITY} from "../../../utility/constants/new-entity.constants";
import {onNavigation} from "../../../+state/on-navigation.operator";
import {ArticleStorageLocationsComponent} from "../article-storage-locations.component";
import {StorageLocationArticleService} from "../../../utility/services/storage-location-article.service";
import {getSelectedId} from "../../../articles/+state/article.selectors";

@Injectable()
export class ArticleStorageLocationEffects {
  articleStorageLocationIndex$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(ArticleStorageLocationsComponent),
      map( () => ArticleStorageLocationActions.loadArticleStorageLocations())
    )
  );

  articleStorageLocationView$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(ArticleStorageLocationsComponent),
      filter( action => action.payload.routerState.params['articleStorageLocationId'] !== NEW_ENTITY),
      map( (action) => {
        const articleStorageLocationId = parseInt(action.payload.routerState.params['articleStorageLocationId'], 10);

        if (!articleStorageLocationId) {
          throw new Error('articleStorageLocation id is missing');
        }

        return ArticleStorageLocationActions.setSelectedArticleStorageLocation({ id: articleStorageLocationId });
      })
    )
  );

  selectArticleStorageLocation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ArticleStorageLocationActions.setSelectedArticleStorageLocation),
        concatLatestFrom(() => this.store.select(getSelected)),
        switchMap(([action, selected]) => {
          if (!selected) {
            return this.articleStorageLocationService.loadStorageLocationArticles().pipe(
              map(res => ArticleStorageLocationActions.loadArticleStorageLocationDetailSuccess({ articleStorageLocation: res.items[0] }))
            );
          }

          return of(ArticleStorageLocationActions.loadArticleStorageLocationDetailUnneeded());
        }),
        catchError((error) => {
          return of(ArticleStorageLocationActions.loadArticleStorageLocationsFailure({ error }));
        })
      )
  );

  loadArticleStorageLocation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ArticleStorageLocationActions.loadArticleStorageLocations),
        concatLatestFrom(() => [
          this.store.select(getArticleStorageLocationQuery),
          this.store.select(getSelectedId),
          this.store.select(getEmptyFilter),
        ]),
        switchMap(([action, query, articleId, emptyFilter]) => {
          let completeQuery = query;
          completeQuery += '&storageLocationId=' + (action.filterId ?? 0);
          completeQuery += '&articleId=' + (action.filterId !== 0 ? 0 : (articleId ?? 0));
          completeQuery += '&show-empty=' + (emptyFilter ? 1 : 0);

          return this.articleStorageLocationService
            .loadStorageLocationArticles(completeQuery)
            .pipe(
              tap(() => console.log('Store Load Complete')),
              map(res => ArticleStorageLocationActions.loadArticleStorageLocationsSuccess({ articleStorageLocation: res.items, total: res.meta.itemCount })),
          );
        }),
        catchError((error) => {
          return of(ArticleStorageLocationActions.loadArticleStorageLocationsFailure({ error }));
        })
      )
  );

  setArticleStorageLocationFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ArticleStorageLocationActions.setArticleStorageLocationPage,
        ArticleStorageLocationActions.setArticleStorageLocationTerm,
        ArticleStorageLocationActions.setArticleStorageLocationSort,
        ArticleStorageLocationActions.changeEmptyFilter,
      ),
      map(() => ArticleStorageLocationActions.loadArticleStorageLocations())
    )
  );

  createArticleStorageLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleStorageLocationActions.createArticleStorageLocation),
      switchMap((action) => {
        return this.articleStorageLocationService.saveLocation(action.articleStorageLocation).pipe(
          map(created =>
            ArticleStorageLocationActions.saveArticleStorageLocationSuccess( { articleStorageLocation: created, insert: true })
          ),
          catchError(error => {
            return of(ArticleStorageLocationActions.saveArticleStorageLocationFailure({ error }));
          })
        )
      })
    )
  );

  updateArticleStorageLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleStorageLocationActions.updateArticleStorageLocation),
      switchMap((action) => {
        return this.articleStorageLocationService.saveLocation(action.articleStorageLocation).pipe(
          map(updated =>
            ArticleStorageLocationActions.saveArticleStorageLocationSuccess( { articleStorageLocation: updated, insert: false })
          ),
          catchError(error => {
            return of(ArticleStorageLocationActions.saveArticleStorageLocationFailure({ error }));
          })
        )
      })
    )
  );

  deleteArticleStorageLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleStorageLocationActions.deleteArticleStorageLocation),
      switchMap((action) => {
        return this.articleStorageLocationService.deleteLocation(action.articleStorageLocation).pipe(
          map(() =>
            ArticleStorageLocationActions.deleteArticleStorageLocationSuccess( { id: action.articleStorageLocation.id ?? 0 })
          ),
          catchError(error => {
            return of(ArticleStorageLocationActions.deleteArticleStorageLocationFailure({ error }));
          })
        )
      })
    )
  );

  constructor(private readonly actions$: Actions,
              private articleStorageLocationService: StorageLocationArticleService,
              private store: Store<ArticleStorageLocationPartialState>) {
  }
}
