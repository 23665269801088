import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {Action, createReducer, on} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";

import * as TrunkActions from "./trunk.actions";
import {Trunk, TrunkArticle, TrunkBooking} from "@knust/api-interfaces";

export const TRUNK_FEATURE_KEY = 'trunk';

export interface State extends EntityState<Trunk> {
  selectedId?: number; // which Trunk record has been selected
  loaded: boolean; // has the Trunk list been loaded
  error?: string | null; // last known error (if any)
  showCompleted: boolean;
  total: number;
  page: PageEvent;
  term: string;
  sort: Sort | null;
  articles: TrunkArticle[];
  articleQuery: {
    total: number;
    page: PageEvent;
    term: string;
    sort: Sort | null;
  }
  bookings: TrunkBooking[];
  bookingsQuery: {
    total: number;
    page: PageEvent;
    term: string;
    sort: Sort | null;
  },
  reports: Trunk[];
  reportsQuery: {
    total: number;
    page: PageEvent;
    term: string;
    sort: Sort | null;
    showCompleted: boolean;
    userId?: number;
    articleId?: number;
    dateStart?: Date;
    dateEnd?: Date;
  }
  cart: Trunk;
}

export interface TrunkPartialState {
  readonly [TRUNK_FEATURE_KEY]: State;
}

export const trunkAdapter: EntityAdapter<Trunk> =
  createEntityAdapter<Trunk>();

const initialTrunkSort: Sort = {
  active: 'updatedDate',
  direction: 'desc'
}

const initialArticleSort: Sort = {
  active: 'id',
  direction: 'asc'
}

const initialBookingsSort: Sort = {
  active: 'createdDate',
  direction: 'desc'
}

const initialPaginationState = {
  total: 0,
  page: {
    pageIndex: 0,
    pageSize: 25,
    length: 0
  },
  term: '',
  sort: initialTrunkSort
};

// TODO: Reale Nutzer-ID verwenden (oder entfernen)
const initialCartState = {
  id: 0,
  owner: 1,
  completed: false,
  isCart: true,
  logMail: '',
  articles: []
}

export const initialState: State = trunkAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  showCompleted: false,
  ...initialPaginationState,
  cart: {
    ...initialCartState,
  },
  articles: [],
  articleQuery: {
    ...initialPaginationState,
    sort: initialArticleSort
  },
  bookings: [],
  bookingsQuery: {
    ...initialPaginationState,
    sort: initialBookingsSort
  },
  reports: [],
  reportsQuery: {
    ...initialPaginationState,
    showCompleted: false,
  }
});

const trunkReducer = createReducer(
  initialState,
  on(TrunkActions.loadTrunk, (state) => ({ ...state, loaded: false, error: null })),
  on(TrunkActions.loadTrunkSuccess, (state, { trunk, total }) =>
    trunkAdapter.setAll(trunk, { ...state, loaded: true, total })
  ),
  on(TrunkActions.loadTrunkFailure, (state, { error }) => ({ ...state, error })),
  on(TrunkActions.changeCompletedFilter, (state) => ({...state, showCompleted: !state.showCompleted})),
  on(TrunkActions.changeTrunkReportsCompletedFilter, (state) => (
    {
      ...state,
      reportsQuery: {
        ...state.reportsQuery,
        showCompleted: !state.reportsQuery.showCompleted
      }
    })
  ),
  on(TrunkActions.loadTrunkArticlesSuccess, (state, { trunkArticles, total }) =>
    ({...state, articles: trunkArticles, articleQuery: {...state.articleQuery, total } })
  ),
  on(TrunkActions.loadTrunkBookingsSuccess, (state, { trunkBookings, total }) =>
    ({...state, bookings: trunkBookings, bookingsQuery: {...state.bookingsQuery, total } })
  ),
  on(TrunkActions.loadTrunkReportsSuccess, (state, { trunkReports, total }) =>
    ({...state, reports: trunkReports, reportsQuery: {...state.reportsQuery, total: total }})
  ),
  on(TrunkActions.changeItemsInTrunkSuccess, (state, { trunkArticles }) => {
      return {
        ...state,
        articles: trunkArticles
      }
    }
  ),
  on(TrunkActions.changeItemsInTrunkFailure, (state, { error }) => ({ ...state, error })),
  on(TrunkActions.loadCartSuccess, (state, { cart }) =>
    ({ ...state, cart: cart })
  ),
  on(TrunkActions.loadCartFailure, (state, { error }) => ({ ...state, error })),
  on(TrunkActions.addItemsToCartSuccess, (state, { cart }) =>
    ({ ...state, cart: cart })
  ),
  on(TrunkActions.addItemsToCartFailure, (state, { error }) => ({ ...state, error })),
  on(TrunkActions.changeItemInCartSuccess, (state, { trunkArticles, note }) => {
    return {
        ...state,
        cart: {
          ...state.cart,
          note,
          articles: trunkArticles
        }
      }
    }
  ),
  on(TrunkActions.changeItemInCartFailure, (state, { error }) => ({ ...state, error })),
  on(TrunkActions.deleteItemFromCartSuccess, (state, { trunkArticleId }) => {
    const cartArticles = [...state.cart.articles];
    const deletedArticleIndex = cartArticles.findIndex(article => article.id === trunkArticleId);
    cartArticles.splice(deletedArticleIndex, 1);

    return {
      ...state,
      cart: {
        ...state.cart,
        articles: cartArticles
      }
    }
  }),
  // TODO: Auch hier die richtige Nutzer-ID verwenden
  on(TrunkActions.closeCartSuccess, (state, { cart }) => {
    return trunkAdapter.addOne(cart, {
      ...state,
      cart: {
        id: 0,
        owner: 1,
        completed: false,
        isCart: true,
        logMail: '',
        articles: []
      }
    });
  }),
  on(TrunkActions.closeCartFailure, (state, { error }) => ({ ...state, error })),
  on(TrunkActions.closeTrunkSuccess, (state, { trunk }) => {
    return trunkAdapter.updateOne({
      id: trunk.id,
      changes: { completed: true, articles: trunk.articles }
    },
      {
      ...state,
      articles: trunk.articles
    });
  }),
  on(TrunkActions.closeTrunkFailure, (state, { error }) => ({ ...state, error })),
  on(TrunkActions.setSelectedTrunk, (state, { id }) => ({ ...state, selectedId: id })),
  on(TrunkActions.unsetSelectedTrunk, (state) => ({ ...state, selectedId: undefined, articles: [], bookings: [] })),
  on(TrunkActions.loadTrunkDetailSuccess, (state, { trunk }) =>
    trunkAdapter.setAll([trunk], { ...state, loaded: false, ...initialPaginationState })
  ),
  on(TrunkActions.loadTrunkDetailFailure, (state, { error }) => ({ ...state, error })),
  on(TrunkActions.setTrunkPage, (state, { page }) => ({ ...state, page })),
  on(TrunkActions.setTrunkTerm, (state, { term }) => ({ ...state, term })),
  on(TrunkActions.setTrunkSort, (state, { sort }) => ({ ...state, sort })),
  on(TrunkActions.setTrunkArticlesPage, (state, { page }) => ({ ...state, articleQuery: {...state.articleQuery, page } })),
  on(TrunkActions.setTrunkArticlesTerm, (state, { term }) => ({ ...state, articleQuery: {...state.articleQuery, term } })),
  on(TrunkActions.setTrunkArticlesSort, (state, { sort }) => ({ ...state, articleQuery: {...state.articleQuery, sort } })),
  on(TrunkActions.setTrunkBookingsPage, (state, { page }) => ({ ...state, bookingsQuery: {...state.bookingsQuery, page } })),
  on(TrunkActions.setTrunkBookingsTerm, (state, { term }) => ({ ...state, bookingsQuery: {...state.bookingsQuery, term } })),
  on(TrunkActions.setTrunkBookingsSort, (state, { sort }) => ({ ...state, bookingsQuery: {...state.bookingsQuery, sort } })),
  on(TrunkActions.setTrunkReportsPage, (state, { page }) => ({ ...state, reportsQuery: {...state.reportsQuery, page} })),
  on(TrunkActions.setTrunkReportsTerm, (state, { term }) => ({ ...state, reportsQuery: {...state.reportsQuery, term} })),
  on(TrunkActions.setTrunkReportsSort, (state, { sort }) => ({ ...state, reportsQuery: {...state.reportsQuery, sort} })),
  on(TrunkActions.setTrunkReportsUserFilter, (state, { userId }) => ({ ...state, reportsQuery: {...state.reportsQuery, userId} })),
  on(TrunkActions.setTrunkReportsArticleFilter, (state, { articleId }) => ({ ...state, reportsQuery: {...state.reportsQuery, articleId} })),
  on(TrunkActions.setTrunkReportsDateFilter, (state, { dateStart, dateEnd }) => ({ ...state, reportsQuery: {...state.reportsQuery, dateStart, dateEnd} })),
  on(TrunkActions.resetReportFilters, (state) => {
    const newReportsQuery = {...state.reportsQuery};
    delete newReportsQuery.userId;
    delete newReportsQuery.articleId;
    delete newReportsQuery.dateStart;
    delete newReportsQuery.dateEnd;

    return {...state, reportsQuery: {...newReportsQuery}};
  }),
  on(TrunkActions.clearTrunk, (state) =>
    trunkAdapter.removeAll({ ...state, ...initialPaginationState })
  ),
  on(TrunkActions.saveTrunkSuccess, (state, { trunk, insert }) =>
    trunkAdapter.upsertOne(trunk, { ...state, total: (insert ? state.total + 1 : state.total) })
  ),
  on(TrunkActions.saveTrunkFailure, (state, { error }) => ({ ...state, error })),
  on(TrunkActions.deleteTrunkSuccess, (state, { id }) =>
    trunkAdapter.removeOne(id + '', { ...state, total: state.total - 1 })
  ),
  on(TrunkActions.deleteTrunkFailure, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return trunkReducer(state, action);
}
