import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Vendor, VendorArticle} from "@knust/api-interfaces";
import {FormBuilder, FormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {VendorService} from "../../../utility/services/vendor.service";
import {debounceTime, filter, ReplaySubject} from "rxjs";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'knust-vendor-article-dialog',
  templateUrl: './vendor-article-dialog.component.html',
  styleUrls: ['./vendor-article-dialog.component.scss'],
})
export class VendorArticleDialogComponent implements OnInit {
  vendorFilterCtrl: FormControl = new FormControl();
  vendorSearchTerm = '';
  public filteredVendors: ReplaySubject<Vendor[]> = new ReplaySubject<Vendor[]>(1);

  showMainVendorArticleHint = false;

  vendorArticleForm: UntypedFormGroup = this.fb.group({
    vendorArticleNumber: ['', Validators.required],
    vendor: this.fb.group({
      id: [undefined, Validators.required]
    }),
    price: [null, Validators.required],
    packagingUnit: [1, Validators.required],
    isMainVendorArticle: [false, Validators.required],
    manufacturerName: [null],
  });

  constructor(
    private vendorService: VendorService,
    public dialogRef: MatDialogRef<VendorArticleDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data?: { vendorArticle: VendorArticle, isFirstVendorArticle: boolean },
  ) {
    console.log(data);
    if (data && data.vendorArticle) {
      this.vendorArticleForm.patchValue(data.vendorArticle);
      this.vendorArticleForm.get('vendor')?.patchValue(data.vendorArticle.vendor.id);

      if (data.vendorArticle.isMainVendorArticle) {
        this.showMainVendorArticleHint = true;
        this.vendorArticleForm.get('isMainVendorArticle')?.disable();
      }
    }

    if (data && data.isFirstVendorArticle) {
      this.showMainVendorArticleHint = true;
      this.vendorArticleForm.get('isMainVendorArticle')?.setValue(true);
      this.vendorArticleForm.get('isMainVendorArticle')?.disable();
    }

    this.filterVendors();
  }

  ngOnInit() {
    this.vendorFilterCtrl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(500),
        filter(value => value !== this.vendorSearchTerm)
      )
      .subscribe(() => {
        this.filterVendors();
      });
  }

  filterVendors() {
    this.vendorSearchTerm = this.vendorFilterCtrl.value ?? '';

    this.vendorService.loadVendors('?page=1&limit=100&search=' + this.vendorSearchTerm.toLowerCase()).subscribe(
      res => this.filteredVendors.next(res.items)
    );
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    if (this.vendorArticleForm.valid) {
      this.dialogRef.close({
        id: this.data?.vendorArticle?.id ?? null,
        isMainVendorArticle: this.vendorArticleForm.get('isMainVendorArticle')?.value ?? false,
        ...this.vendorArticleForm.value,
      });
    } else {
      this.vendorArticleForm.markAsTouched();
    }
  }
}
