import {Category} from "./category.dto";
import {CoreProperties} from "./core-properties.dto";

export interface ImportArticle extends CoreProperties {
  internalId: string;
  title: string;
  description?: string;
  active: boolean;
  soldOut: boolean;
  unitOfMeasure: string;
  samplingStep: number;
  autoOrder: boolean;
  weight?: number;
  size_length?: number;
  size_width?: number;
  size_height?: number;
}
