import {Injectable} from "@angular/core";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {Store} from "@ngrx/store";
import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";

import * as TrunkActions from "./trunk.actions";
import {loadTrunkArticles, loadTrunkBookings} from "./trunk.actions";
import {TrunkPartialState} from "./trunk.reducer";
import {
  getCompletedFilter,
  getReportsArticleFilter,
  getReportsCompletedFilter,
  getReportsDateEndFilter,
  getReportsDateStartFilter,
  getReportsUserFilter,
  getSelected,
  getSelectedId,
  getTrunkArticlesQuery,
  getTrunkBookingsQuery,
  getTrunkQuery,
  getTrunkReportsQuery
} from "./trunk.selectors";
import {TrunksIndexComponent} from "../trunks-index/trunks-index.component";
import {TrunksDetailComponent} from "../trunks-detail/trunks-detail.component";
import {onNavigation} from "../../+state/on-navigation.operator";
import {NEW_ENTITY} from "../../utility/constants/new-entity.constants";
import {TrunkService} from "../../utility/services/trunk.service";
import {CartComponent} from "../cart/cart.component";
import {Router} from "@angular/router";
import {ReportsComponent} from "../../reports/reports.component";

@Injectable()
export class TrunkEffects {
  trunkIndex$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(TrunksIndexComponent),
      map( () => TrunkActions.loadTrunk())
    )
  );

  trunkView$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(TrunksDetailComponent),
      tap(() => console.log('Loading Trunk Details')),
      map( (action) => {
        console.log('Loading Trunk Step 2');
        if (action.payload.routerState.params['trunkId'] === NEW_ENTITY) {
          return TrunkActions.unsetSelectedTrunk();
        }

        const trunkId = parseInt(action.payload.routerState.params['trunkId'], 10);

        if (!trunkId) {
          throw new Error('trunk id is missing');
        }

        return TrunkActions.setSelectedTrunk({ id: trunkId });
      })
    )
  );

  trunkReports$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(ReportsComponent),
      tap(() => TrunkActions.resetReportFilters()),
      map( () => TrunkActions.loadTrunkReports())
    )
  );

  selectTrunk$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TrunkActions.setSelectedTrunk),
        concatLatestFrom(() => this.store.select(getSelected)),
        switchMap(([action, selected]) => {
          if (!selected) {
            console.log('Not selected - loading!')
            return this.trunkService.loadTrunkDetails(action.id).pipe(
              map(res => TrunkActions.loadTrunkDetailSuccess({ trunk: res }))
            );
          }

          return of(TrunkActions.loadTrunkDetailUnneeded());
        }),
        catchError((error) => {
          return of(TrunkActions.loadTrunkFailure({ error }));
        })
      )
  );

  loadTrunk$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TrunkActions.loadTrunk),
        concatLatestFrom(() => [
          this.store.select(getTrunkQuery),
          this.store.select(getCompletedFilter)
        ]),
        switchMap(([action, query, completed]) => {
          return this.trunkService.loadTrunks(query + '&completed=' + (completed ? '1' : '0')).pipe(
            tap(() => console.log('Store Load Complete')),
            map(res => TrunkActions.loadTrunkSuccess({ trunk: res.items, total: res.meta.itemCount })),
          );
        }),
        catchError((error) => {
          return of(TrunkActions.loadTrunkFailure({ error }));
        })
      )
  );

  loadTrunkArticles$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          TrunkActions.setSelectedTrunk,
          TrunkActions.loadTrunkArticles
        ),
        concatLatestFrom(() => [
          this.store.select(getTrunkArticlesQuery),
          this.store.select(getSelectedId)
        ]),
        switchMap(([action, query, trunkId]) => {
          if (trunkId) {
            return this.trunkService.loadTrunkArticles(trunkId, query).pipe(
              tap(() => console.log('Store Load Complete')),
              map(res => TrunkActions.loadTrunkArticlesSuccess({ trunkArticles: res.items, total: res.meta.itemCount })),
            );
          }

          return of(TrunkActions.loadTrunkFailure({ error: 'Keine Entnahme ausgewählt.' }));
        }),
        catchError((error) => {
          return of(TrunkActions.loadTrunkFailure({ error }));
        })
      )
  );

  loadTrunkBookings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          TrunkActions.setSelectedTrunk,
          TrunkActions.loadTrunkBookings
        ),
        concatLatestFrom(() => [
          this.store.select(getTrunkBookingsQuery),
          this.store.select(getSelectedId)
        ]),
        switchMap(([action, query, trunkId]) => {
          if (trunkId) {
            return this.trunkService.loadTrunkBookings(trunkId, query).pipe(
              tap(() => console.log('Store Load Complete')),
              map(res => TrunkActions.loadTrunkBookingsSuccess({ trunkBookings: res.items, total: res.meta.itemCount })),
            );
          }

          return of(TrunkActions.loadTrunkBookingsFailure({ error: 'Keine Entnahme ausgewählt.' }));
        }),
        catchError((error) => {
          return of(TrunkActions.loadTrunkBookingsFailure({ error }));
        })
      )
  );

  loadTrunkReports$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TrunkActions.loadTrunkReports),
        concatLatestFrom(() => [
          this.store.select(getTrunkReportsQuery),
          this.store.select(getReportsCompletedFilter),
          this.store.select(getReportsUserFilter),
          this.store.select(getReportsArticleFilter),
          this.store.select(getReportsDateStartFilter),
          this.store.select(getReportsDateEndFilter),
        ]),
        switchMap(([action, query, completed, userId, articleId, startDate, endDate]) => {
          let completeQuery = query;

          console.log('LOAD DELIVERY REPORTS', startDate, startDate?.toISOString());
          completeQuery += ('&completed=' + (completed ? '1' : '0'));
          completeQuery += ('&userId=' + (userId ?? ''));
          completeQuery += ('&articleId=' + (articleId ?? ''));
          completeQuery += ('&startDate=' + (startDate ? startDate.toISOString().substring(0, 10) :  ''));
          completeQuery += ('&endDate=' + (endDate ? endDate.toISOString().substring(0, 10) : ''));
          completeQuery += ('&exportData=' + (action.download ? '1' : '0'));

          return this.trunkService.loadTrunkReports(completeQuery).pipe(
            map(res => {
              if (!action.download) {
                return TrunkActions.loadTrunkReportsSuccess({trunkReports: res.items, total: res.meta.itemCount});
              } else {
                const downloadUrl = this.trunkService.downloadTrunkReport((res as any).filePath);

                const a = document.createElement("a");
                a.href = downloadUrl;
                const filename = "export-entnahmen";
                a.setAttribute("download", filename + ".csv");
                a.setAttribute("target", "_blank");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                return TrunkActions.downloadTrunkReport({ url: (res as any).filePath });
              }
            }),
          );
        }),
        catchError((error) => {
          return of(TrunkActions.loadTrunkFailure({ error }));
        })
      )
  );

  changeItemsInTrunk$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TrunkActions.changeItemsInTrunk),
        concatLatestFrom(() => this.store.select(getSelectedId)),
        switchMap(([action, trunkId]) => {
          if (trunkId) {
            return this.trunkService.changeItemInTrunk({trunkId, articles: action.changedItems}).pipe(
              tap(() => this.store.dispatch(loadTrunkBookings())),
              map(res => TrunkActions.changeItemsInTrunkSuccess( { trunkArticles: res.articles } ))
            )
          }

          return of(TrunkActions.changeItemsInTrunkFailure({ error: 'Es ist keine Entnahme ausgewählt.' }));
        }),
        catchError((error) => {
          return of(TrunkActions.changeItemsInTrunkFailure({ error }));
        })
      )
  )

  resetTrunk$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TrunkActions.resetTrunk),
        concatLatestFrom(() => this.store.select(getSelectedId)),
        switchMap(([action, trunkId]) => {
          if (trunkId) {
            return this.trunkService.resetTrunk(trunkId).pipe(
              tap(() => this.store.dispatch(loadTrunkBookings())),
              tap(() => this.store.dispatch(loadTrunkArticles())),
              map(() => TrunkActions.resetTrunkSuccess())
            )
          }

          return of(TrunkActions.resetTrunkFailure({ error: 'Es ist keine Entnahme ausgewählt.' }))
        }),
        catchError((error) => {
          return of(TrunkActions.changeItemsInTrunkFailure({ error }));
        })
      )
  )

  cartView$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(CartComponent),
      map( () => TrunkActions.loadCart())
    )
  );

  loadCart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TrunkActions.loadCart),
        switchMap(() => {
          return this.trunkService.loadCart().pipe(
            map(res => TrunkActions.loadCartSuccess({ cart: res })),
          );
        }),
        catchError((error) => {
          return of(TrunkActions.loadCartFailure({ error }));
        })
      )
  )

  addItemsToCart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TrunkActions.addItemsToCart),
        switchMap((action) => {
          return this.trunkService.addItemsToCart(action.items).pipe(
            map(res => TrunkActions.addItemsToCartSuccess({ cart: res }))
          )
        }),
        catchError((error) => {
          return of(TrunkActions.addItemsToCartFailure({ error }));
        })
      )
  )

  changeItemInCart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TrunkActions.changeItemInCart),
        switchMap((action) => {
          return this.trunkService.changeItemInCart(action.withdrawnStockChanges, action.note).pipe(
            map(res => TrunkActions.changeItemInCartSuccess( { trunkArticles: res.trunkArticles, note: res.note } ))
          )
        }),
        catchError((error) => {
          return of(TrunkActions.changeItemInCartFailure({ error }));
        })
      )
  )

  deleteItemFromCart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TrunkActions.deleteItemFromCart),
        switchMap((action) => {
          return this.trunkService.deleteItemFromCart(action.trunkArticleId).pipe(
            map(res => TrunkActions.deleteItemFromCartSuccess( { trunkArticleId: res.id } ))
          )
        })
      )
  )

  closeCart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TrunkActions.closeCart),
        switchMap((action) => {
          return this.trunkService.closeCart(action.withdrawnStockChanges, action.note).pipe(
            map(res => TrunkActions.closeCartSuccess( { cart: res } ))
          )
        }),
        catchError((error) => {
          return of(TrunkActions.closeCartFailure( { error }));
        })
      )
  )

  closeCartSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        TrunkActions.closeCartSuccess
      ),
      tap( action => {
        console.log('Starting Navigation');
        this.router.navigate(['entnahmen', action.cart.id]);
      })
    ), { dispatch: false }
  );

  closeTrunk$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TrunkActions.closeTrunk),
        concatLatestFrom(() => this.store.select(getSelectedId)),
        switchMap(([action, selectedId]) => {
          if (selectedId) {
            return this.trunkService.closeTrunk(action.withdrawnStockChanges, selectedId).pipe(
              tap(() => this.store.dispatch(loadTrunkBookings())),
              map(res => TrunkActions.closeTrunkSuccess( { trunk: res } ))
            )
          }

          return of(TrunkActions.closeTrunkFailure( { error: 'Es ist keine Entnahme ausgewählt.' }));
        }),
        catchError((error) => {
          return of(TrunkActions.closeTrunkFailure( { error }));
        })
      )
  )

  closeTrunkSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        TrunkActions.closeTrunkSuccess
      ),
      tap( () => {
        console.log('Starting Navigation');
        this.router.navigate(['entnahmen']);
      })
    ), { dispatch: false }
  );

  setTrunkFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        TrunkActions.setTrunkPage,
        TrunkActions.setTrunkTerm,
        TrunkActions.setTrunkSort,
        TrunkActions.changeCompletedFilter,
      ),
      map(() => TrunkActions.loadTrunk())
    )
  );

  setTrunkArticlesFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        TrunkActions.setTrunkArticlesPage,
        TrunkActions.setTrunkArticlesTerm,
        TrunkActions.setTrunkArticlesSort
      ),
      map(() => TrunkActions.loadTrunkArticles())
    )
  );

  setTrunkBookingsFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        TrunkActions.setTrunkBookingsPage,
        TrunkActions.setTrunkBookingsTerm,
        TrunkActions.setTrunkBookingsSort
      ),
      map(() => TrunkActions.loadTrunkBookings())
    )
  );

  setTrunkReportsFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        TrunkActions.setTrunkReportsPage,
        TrunkActions.setTrunkReportsTerm,
        TrunkActions.setTrunkReportsSort,
        TrunkActions.changeTrunkReportsCompletedFilter,
        TrunkActions.setTrunkReportsUserFilter,
        TrunkActions.setTrunkReportsArticleFilter,
        TrunkActions.setTrunkReportsDateFilter,
      ),
      map(() => TrunkActions.loadTrunkReports())
    )
  );

  saveNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrunkActions.saveTrunkNote),
      concatLatestFrom(() => [
        this.store.select(getSelectedId)
      ]),
      switchMap(([action, selectedId]) => {
        if (selectedId) {
          return this.trunkService.saveNote(action.note, selectedId).pipe(
            map(res => TrunkActions.saveTrunkNoteSuccess( { note: res.note } ))
          )
        }

        return of(TrunkActions.saveTrunkNoteFailure( { error: 'Es ist keine Entnahme ausgewählt.' }));
      }),
    )
  )

  /*createTrunk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrunkActions.createTrunk),
      switchMap((action) => {
        return this.trunkService.saveTrunk(action.trunk).pipe(
          map(created =>
            TrunkActions.saveTrunkSuccess( { trunk: created, insert: true })
          ),
          catchError(error => {
            return of(TrunkActions.saveTrunkFailure({ error }));
          })
        )
      })
    )
  );*/

  /*updateTrunk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrunkActions.updateTrunk),
      switchMap((action) => {
        return this.trunkService.saveTrunk(action.trunk).pipe(
          map(updated =>
            TrunkActions.saveTrunkSuccess( { trunk: updated, insert: false })
          ),
          catchError(error => {
            return of(TrunkActions.saveTrunkFailure({ error }));
          })
        )
      })
    )
  );*/

  /*deleteTrunk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrunkActions.deleteTrunk),
      switchMap((action) => {
        return this.trunkService.deleteTrunk(action.trunk).pipe(
          map(() =>
            TrunkActions.deleteTrunkSuccess( { id: action.trunk.id ?? 0 })
          ),
          catchError(error => {
            return of(TrunkActions.deleteTrunkFailure({ error }));
          })
        )
      })
    )
  );*/

  constructor(private readonly actions$: Actions,
              private router: Router,
              private trunkService: TrunkService,
              private store: Store<TrunkPartialState>) {
  }
}
