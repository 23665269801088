import {StorageLocation} from "./storage-location.dto";
import {Article} from "./article.dto";

export interface StorageLocationArticle {
  id?: number;
  actualStock: number;
  notificationStock: number;
  autoOrderLessStock: number;
  targetStock: number;
  storageLocation: StorageLocation;
  article?: Article;
}
