import {createAction, props} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";
import {ImportArticle} from "@knust/api-interfaces";

export const loadImportArticle = createAction('[ImportArticle] Load ImportArticle');

export const loadImportArticleSuccess = createAction(
  '[ImportArticle/API] Load ImportArticle Success',
  props<{ importArticle: ImportArticle[], total: number }>()
);

export const loadImportArticleFailure = createAction(
  '[ImportArticle/API] Load ImportArticle Failure',
  props<{ error: any }>()
);

export const setSelectedImportArticle = createAction(
  '[ImportArticle] Set selected ImportArticle',
  props<{ id: number }>()
);

export const unsetSelectedImportArticle = createAction(
  '[ImportArticle] Unset selected ImportArticle',
);

export const loadImportArticleDetailUnneeded = createAction(
  '[ImportArticle/API] Load ImportArticle Detail Unneeded'
);

export const loadImportArticleDetailSuccess = createAction(
  '[ImportArticle/API] Load ImportArticle Detail Success',
  props<{ importArticle: ImportArticle }>()
);

export const loadImportArticleDetailFailure = createAction(
  '[ImportArticle/API] Load ImportArticle Detail Failure',
  props<{ error: any }>()
);

export const setImportArticlePage = createAction(
  '[ImportArticle/API] Set Page',
  props<{ page: PageEvent }>()
);

export const setImportArticleTerm = createAction(
  '[ImportArticle/API] Set Term',
  props<{ term: string }>()
);

export const setImportArticleSort = createAction(
  '[ImportArticle/API] Set Sort',
  props<{ sort: Sort | null }>()
);

export const clearImportArticle = createAction(
  '[ImportArticle/API] Clear'
);

export const createImportArticle = createAction(
  '[ImportArticle/API] Create ImportArticle',
  props<{ importArticle: Omit<ImportArticle, 'id'> }>()
);

export const updateImportArticle = createAction(
  '[ImportArticle/API] Update ImportArticle',
  props<{ importArticle: ImportArticle }>()
);

export const saveImportArticleSuccess = createAction(
  '[ImportArticle/API] Save ImportArticle Success',
  props<{ importArticle: ImportArticle, insert: boolean }>()
);

export const saveImportArticleFailure = createAction(
  '[ImportArticle/API] Save ImportArticle Failure',
  props<{ error: any }>()
);

export const deleteImportArticle = createAction(
  '[ImportArticle/API] Delete ImportArticle',
  props<{ id: number }>()
);

export const deleteImportArticleSuccess = createAction(
  '[ImportArticle/API] Delete ImportArticle Success',
  props<{ id: number | string }>()
);

export const deleteImportArticleFailure = createAction(
  '[ImportArticle/API] Delete ImportArticle Failure',
  props<{ error: any }>()
);

export const restoreImportArticle = createAction(
  '[ImportArticle/API] Restore ImportArticle',
  props<{ element: ImportArticle }>()
);

export const restoreImportArticleSuccess = createAction(
  '[ImportArticle/API] Restore ImportArticle Success',
  props<{ importArticle: ImportArticle }>()
);

export const restoreImportArticleFailure = createAction(
  '[ImportArticle/API] Restore ImportArticle Failure',
  props<{ error: any }>()
);
