<h1 mat-dialog-title>{{'Lieferanten-Artikel-Beziehung ' + (data?.vendorArticle ? 'editieren' : 'erstellen')}}</h1>
<form mat-dialog-content [formGroup]="vendorArticleForm" (keydown.enter)="save()">
  <mat-form-field appearance="fill" formGroupName="vendor">
    <mat-label>Lieferant</mat-label>
    <mat-select formControlName="id">
      <mat-option>
        <ngx-mat-select-search [formControl]="vendorFilterCtrl"></ngx-mat-select-search>
      </mat-option>
      <mat-option [value]="vendor.id" *ngFor="let vendor of filteredVendors | async">
        {{vendor.organization.name}} ({{vendor.vendorNumber}})
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Lieferanten-Artikelnummer</mat-label>
    <input matInput formControlName="vendorArticleNumber">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Preis</mat-label>
    <input matInput formControlName="price" type="number">
    <mat-icon matSuffix>euro_symbol</mat-icon>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Verpackungseinheit</mat-label>
    <input matInput formControlName="packagingUnit" type="number">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Hersteller</mat-label>
    <input matInput formControlName="manufacturerName">
  </mat-form-field>

  <div class="main-vendor-article-checkbox-container">
    <mat-checkbox formControlName="isMainVendorArticle"
                  color="primary">
      Hauptlieferant für diesen Artikel
    </mat-checkbox>
    <mat-hint *ngIf="showMainVendorArticleHint">Der Hauptlieferant kann nur gewechselt werden, indem ein neuer Hauptlieferant aktiviert wird.</mat-hint>
  </div>
</form>
<div mat-dialog-actions>
  <button mat-flat-button (click)="cancel()">Abbrechen</button>
  <button mat-flat-button color="primary" (click)="save()">Bestätigen</button>
</div>
