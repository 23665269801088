import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {Action, createReducer, on} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";

import * as UserActions from "./user.actions";
import {User} from "@knust/api-interfaces";

export const USER_FEATURE_KEY = 'user';

export interface State extends EntityState<User> {
  selectedId?: number; // which User record has been selected
  loaded: boolean; // has the User list been loaded
  error?: string | null; // last known error (if any)
  total: number;
  page: PageEvent;
  term: string;
  sort: Sort | null;
}

export interface UserPartialState {
  readonly [USER_FEATURE_KEY]: State;
}

export const userAdapter: EntityAdapter<User> =
  createEntityAdapter<User>();

const initialPaginationState = {
  total: 0,
  page: {
    pageIndex: 0,
    pageSize: 25,
    length: 0
  },
  term: '',
  sort: null
};

export const initialState: State = userAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  ...initialPaginationState
});

const userReducer = createReducer(
  initialState,
  on(UserActions.loadUsers, (state) => ({ ...state, loaded: false, error: null })),
  on(UserActions.loadUsersSuccess, (state, { user, total }) =>
    userAdapter.setAll(user, { ...state, loaded: true, total })
  ),
  on(UserActions.loadUsersFailure, (state, { error }) => ({ ...state, error })),
  on(UserActions.setSelectedUser, (state, { id }) => ({ ...state, selectedId: id })),
  on(UserActions.loadUserDetailSuccess, (state, { user }) =>
    userAdapter.setAll([user], { ...state, loaded: false, ...initialPaginationState })
  ),
  on(UserActions.loadUserDetailFailure, (state, { error }) => ({ ...state, error })),
  on(UserActions.setUserPage, (state, { page }) => ({ ...state, page })),
  on(UserActions.setUserTerm, (state, { term }) => ({ ...state, term })),
  on(UserActions.setUserSort, (state, { sort }) => ({ ...state, sort })),
  on(UserActions.clearUser, (state) =>
    userAdapter.removeAll({ ...state, ...initialPaginationState })
  ),
  on(UserActions.saveUserSuccess, (state, { user, insert }) =>
    {
      const newState = {...state};

      return userAdapter.upsertOne(user, { ...newState, total: (insert ? newState.total + 1 : newState.total) });
    }
  ),
  on(UserActions.saveUserFailure, (state, { error }) => ({ ...state, error })),
  on(UserActions.deleteUserSuccess, (state, { id }) =>
    userAdapter.removeOne(id + '', { ...state, total: state.total - 1 })
  ),
  on(UserActions.deleteUserFailure, (state, { error }) => ({ ...state, error })),
  on(UserActions.restoreUserSuccess, (state, { user }) =>
    userAdapter.removeOne(user.id, { ...state, total: state.total - 1 })
  ),
  on(UserActions.restoreUserFailure, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}
