<knust-notification></knust-notification>

<mat-sidenav-container autosize>
  <mat-sidenav #sidenav [mode]="sidenavMode" [opened]="showSidenav | async" (openedChange)="changeSidenavState($event)">
    <mat-nav-list (keydown.escape)="dontClose(sidenav)">
      <a routerLink="/home" class="logo-link">
        <img [src]="logoPath" alt="Firmenlogo">
      </a>

      <a mat-list-item class="navigation-list-link"
         routerLink="/stammdaten" routerLinkActive="active" *ngIf="authService.isAdmin()">
        <mat-icon matListIcon>home</mat-icon>
        <div matLine>Stammdaten</div>
      </a>

      <a mat-list-item class="navigation-list-link"
         routerLink="/lieferanten" routerLinkActive="active"
         *ngIf="(authService.appInitCompleted | async) === true && (authService.isAdmin() || authService.isPurchasing())">
        <mat-icon matListIcon>local_shipping</mat-icon>
        <div matLine>Lieferanten</div>
      </a>

      <a mat-list-item class="navigation-list-link"
         routerLink="/artikel" routerLinkActive="active"
         *ngIf="(authService.appInitCompleted | async) === true">
        <mat-icon matListIcon>list_alt</mat-icon>
        <div matLine>Artikelübersicht</div>
      </a>

      <a mat-list-item class="navigation-list-link"
         routerLink="/wareneingangsbuchung" routerLinkActive="active"
         *ngIf="(authService.appInitCompleted | async) === true && (authService.isAdmin() || authService.isPurchasing())">
        <mat-icon matListIcon>receipt_long</mat-icon>
        <div matLine>Wareneingangsbuchungen</div>
      </a>

      <mat-divider></mat-divider>

      <a mat-list-item class="navigation-list-link"
         routerLink="/entnahmen/warenkorb" routerLinkActive="active"
         *ngIf="(authService.appInitCompleted | async) === true">
        <mat-icon matListIcon [matBadge]="cartItemCount" matBadgeColor="primary" [matBadgeHidden]="cartItemCount === 0">
          shopping_cart
        </mat-icon>
        <div matLine>Warenkorb</div>
      </a>

      <a mat-list-item class="navigation-list-link"
         *ngIf="(authService.appInitCompleted | async) === true"
         routerLink="/entnahmen" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <mat-icon matListIcon>outbox</mat-icon>
        <div matLine>Entnahmen</div>
      </a>

      <mat-divider *ngIf="(authService.appInitCompleted | async) === true"></mat-divider>

      <a mat-list-item class="navigation-list-link"
         routerLink="/papierkorb" routerLinkActive="active"
         *ngIf="(authService.appInitCompleted | async) === true && authService.isAdmin()">
        <mat-icon matListIcon>delete_outline</mat-icon>
        <div matLine>Papierkorb</div>
      </a>

      <a mat-list-item class="navigation-list-link"
         routerLink="/benutzer" routerLinkActive="active"
         *ngIf="(authService.appInitCompleted | async) === true">
        <mat-icon matListIcon>manage_accounts</mat-icon>
        <div matLine>{{authService.isAdmin() ? 'Benutzer' : 'Meine Daten'}}</div>
      </a>

      <ng-container *ngIf="(authService.appInitCompleted | async) === true && authService.isAdmin()">
        <mat-divider></mat-divider>

        <a mat-list-item class="navigation-list-link"
           routerLink="/import" routerLinkActive="active" *ngIf="authService.isAdmin()">
          <mat-icon matListIcon>import_export</mat-icon>
          <div matLine>Artikel importieren / exportieren</div>
        </a>

        <a mat-list-item class="navigation-list-link"
           routerLink="/berichte" routerLinkActive="active" *ngIf="authService.isAdmin()">
          <mat-icon matListIcon>query_stats</mat-icon>
          <div matLine>Berichte</div>
        </a>
      </ng-container>

      <mat-divider *ngIf="(authService.appInitCompleted | async) === true"></mat-divider>

      <a mat-list-item class="navigation-list-link" routerLinkActive="active" class="logout-link"
         (click)="authService.logout()">
        <mat-icon matListIcon>logout</mat-icon>
        <div matLine>Logout</div>
      </a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <main>
      <button (click)="showSidenav.next(true)" mat-flat-button class="menu-button" *ngIf="!isLoginWindow">
        <mat-icon>menu</mat-icon>
        Menü
      </button>

      <div class="app-init-notification" *ngIf="(authService.appInitCompleted | async) === false">
        <p>
          Die Anwendung steht erst zur Verfügung, wenn die Stammdaten eingetragen wurden und Sie sich erneut einloggen.
        </p>
      </div>

      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
